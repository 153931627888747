class Functions {
    getUrl = () =>{
        const url = window.location.href.split('/')[2].split(':')[0]
        if(url !== 'advertiser.soundrops.com')
            return 'http://' + url + ':8000/clientlogos/';
        else
            return 'https://advertiser.soundrops.com/clientlogos/';
    }

    getLocationUrl = () => {
        return `${window.location.protocol}//${window.location.hostname}${(window.location.port ? `:${window.location.port}` : '')}`;
    }

    getLastPartOfUrl = () =>{
        let url = window.location.pathname.split('/');
        return url[url.length-1];
    }

    getPartOfUrl = (part) => {
        let url = window.location.pathname.split('/');
        return url[parseInt(part)];
    }

    findKeyInStorage = (dataset, key, findwhat) => {
        return JSON.parse(localStorage.getItem(dataset)).find(o => o[key] === findwhat);
    }

    getKeyInStorage = (key) => {
        return JSON.parse(localStorage.getItem(key))
    }

    editSingleFieldDataInLocalStorage = (id, value, key, identifier, objectid, field) => {
        let lstorageobj = this.getKeyInStorage(key);
        for(let i = 0; i < lstorageobj.length; i++ ){
            if(lstorageobj[i][identifier] === objectid){
                lstorageobj[i][field] = value;
            }
        }
        this.storeObject(key, lstorageobj);
        window.dispatchEvent(new Event('storage'))
        if (key === 'user') {
            this.editSingleFieldDataInLocalStorage(id, value, 'member', identifier, objectid, field);
        }
    }

    compileImgStreamUrl = (streamid) => {
        return 'https://imagedelivery.net/aJao3EHUr2in9Tg1bJx-pQ/' + streamid + '/public';
    }

    compileVideoStreamUrl = (streamid) =>{
        return 'https://customer-col1rcz0gf7xiwzn.cloudflarestream.com/' + streamid + '/manifest/video.m3u8';
    }

    compileCmpImageUrl = (file) =>{
        return 'https://advertiser.share50.no/php/campaignimage/' + file;
    }
    compileCmpVideoUrl = (file) =>{
        return 'https://advertiser.share50.no/php/campaignvideo/' + file;
    }

    getUserId = () => {
        return JSON.parse(localStorage.getItem('user')).uid;
    }

    getHost = () => {
        this.protocol = window.location.protocol;
        this.host = (window.location.hostname === "localhost") ? window.location.hostname + ":3001" : window.location.hostname;
        return this.protocol + "//" + this.host + '/';
    }

    /*getStatusOfCampaigns = () => {
        const clients = this.getKeyInStorage("clients");
        let activecount = 0;
        let notapproved = 0;
        for(let i = 0; i < clients.length; i++){
            if(clients[i]['campaigns'].length > 0){
                let campaigns = clients[i]['campaigns'][0];
                for(let c = 0; c < campaigns.length; c++) {
                    if (campaigns[c]['active'] === "1")
                        activecount++;

                    if (campaigns[c]['awaitingapproval'] === "1")
                        notapproved++;
                }
            }
        }
        return {active: activecount, waitingapproval: notapproved};
    }*/

    storeObject = (name, object) => {
        localStorage.setItem(name, JSON.stringify(object));
    }

    getSessionId = () => {
        let session = JSON.parse(localStorage.getItem('session'));
        return session.id;
    }

    createSessionObject = (sessionid) => {
        const session = { id: sessionid, datetime: new Date().getTime()}
        localStorage.setItem('session', JSON.stringify(session));
    }

    getSessionValid = () => {
        try{
            let lifespan = 15 * 60 * 1000;
            let timestamp = this.getKeyInStorage('session');
            return ((new Date().getTime() - timestamp['datetime']) < lifespan);
        } catch(e){
            return false;
        }
    }

    updateSessionTime = () =>{
        if(this.getSessionValid()){
            let session = this.getKeyInStorage('session');
            session.datetime = new Date().getTime();
            localStorage.setItem('session', JSON.stringify(session));
        } else {
            localStorage.clear();
            document.location.href = '/'
        }
    }

    setActivityListener = () => {
        const body = document.getElementById('body');
        body.addEventListener('mouseup', this.updateSessionTime);
    }

    // Movie to file pre upload.
    readFileAsBase64 = async (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    // for file upload
    createFormData = (file, dir, task) =>{
        let formData = new FormData();
        formData.append('fileToUpload', file);
        formData.append('directory', dir);
        formData.append('task', task)
        return formData;
    }
}
const functions = new Functions();
export default functions;
