import React from "react";
import { Link } from "react-router-dom";
import { CircleUserRound } from "lucide-react";
const Topmenu = () => {
  return (
    <>
      <header className="main-header header-one">
        {/*
                <div className="header-top bg-lighter py-10">
                    <div className="top-left">
                        <ul>
                            <li>Contact : <Link to="callto:0041787721182<">+33 6 31 39 26 81 / +41 78 772 11
                                82</Link>&nbsp;</li>
                            <li>Email: <Link to="mailto:info@sybe-solutions.com">info@sybe-solutions.com</Link>&nbsp;
                            </li>
                            <li>Adresse: Pôle de l’entrepreneuriat 50 Rue Louis et August Lumière, 01630
                                Saint-Genis-Pouilly, France
                            </li>
                        </ul>
                    </div>
                    <div className="top-right">
                        <div className="office-time">
                            <i className="far fa-clock">&nbsp;</i>&nbsp;<span>08:00 am - 06:00 pm</span>
                        </div>
                    </div>
                </div>
                */}

        <div className="header-upper bg-white">
          <div className="container-fluid clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer bg-white py-0 px-25">
                <div className="logo">
                  <Link className="tw-flex" to="/">
                    &nbsp;
                    <img
                      style={{ maxWidth: "240px" }}
                      src="/assets/images/logos/logo-web-transparent.png"
                      alt="Logo"
                      title="Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <nav className="main-menu navbar-expand-lg">
                  <div className="navbar-header">
                    <div className="mobile-logo bg-blue p-15">
                      <Link to="index.html">
                        <img
                          src="/assets/images/logos/logo.png"
                          alt="Logo"
                          title="Logo"
                        />
                      </Link>
                    </div>
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-toggle="collapse"
                      data-target=".navbar-collapse"
                    >
                      <span className="icon-bar">&nbsp;</span>
                      <span className="icon-bar">&nbsp;</span>
                      <span className="icon-bar">&nbsp;</span>
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix">
                    <ul className="navigation clearfix tw-gap-6 tw-flex">
                      <li className="main-menu tw-group tw-flex tw-flex-col">
                        &nbsp;
                        <Link
                          to="/cybercompliance"
                          style={{
                            fontSize: "1.0rem",
                          }}
                        >
                          Sybercompliance
                        </Link>
                        <span className="tw-transition-all tw-duration-500 tw-h-0.5 tw-w-0 group-hover:tw-bg-main-blue group-hover:tw-w-full"></span>
                      </li>
                      <li className="main-menu tw-group tw-flex tw-flex-col ">
                        &nbsp;
                        <Link
                          to="/solutions"
                          style={{
                            fontSize: "1.0rem",
                          }}
                        >
                          Solutions
                        </Link>
                        <span className="tw-transition-all tw-duration-500 tw-h-0.5 tw-w-0 group-hover:tw-bg-main-blue group-hover:tw-w-full"></span>
                      </li>
                      <li className="dropdown tw-group tw-flex tw-flex-col">
                        &nbsp;
                        <Link
                          to="/about"
                          style={{
                            fontSize: "1.0rem",
                          }}
                        >
                          Qui sommes nous
                        </Link>
                        <span className="tw-transition-all tw-duration-500 tw-h-0.5 tw-w-0 group-hover:tw-bg-main-blue group-hover:tw-w-full"></span>
                      </li>
                      <li className="dropdown tw-group tw-flex tw-flex-col">
                        &nbsp;
                        <Link
                          to="/blog"
                          style={{
                            fontSize: "1.0rem",
                          }}
                        >
                          Blog
                        </Link>
                        <span className="tw-transition-all tw-duration-500 tw-h-0.5 tw-w-0 group-hover:tw-bg-main-blue group-hover:tw-w-full"></span>
                      </li>
                      <li className="dropdown tw-group tw-flex tw-flex-col">
                        &nbsp;
                        <Link
                          to="/contact"
                          style={{
                            fontSize: "1.0rem",
                          }}
                        >
                          Contact{" "}
                        </Link>
                        <span className="tw-transition-all tw-duration-500 tw-h-0.5 tw-w-0 group-hover:tw-bg-main-blue group-hover:tw-w-full"></span>
                      </li>
                      <li className="dropdown tw-group tw-flex tw-flex-col">
                        &nbsp;
                        <Link
                          to="/authenticate"
                          style={{
                            fontSize: "1.0rem",
                          }}
                        >
                          <CircleUserRound />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Topmenu;
