import {Link} from "react-router-dom";
import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";
import React from "react";
import Home from "../components/home";

const Footer = () => {
    return (
        <>
        </>
    )
}

export default Footer;