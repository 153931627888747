import React from "react";
import {Link} from "react-router-dom";

const UncompleteBadge = ({name, link}) => {

    return (
        <div className="">
            <Link
                to={link}
                className="theme-btn"
                style={{
                    display: 'inline-block',
                    width: '160px',
                    height: '60px',
                    padding: '20px',
                    margin: '20px',
                    textAlign: 'center',
                    lineHeight: '20px',
                    boxSizing: 'border-box'
                }}
            >
                {name} <i className="fas fa-arrow-right"></i>
            </Link>
        </div>
    );
}

export default UncompleteBadge;