import React from "react";
import TeamV4Data from "./TeamV4Data.json";
import SingleTeamV4 from "./SingleTeamV4";

const TeamV4 = ({ partial = true, teamClass }) => {
  return (
    <>
      <section
        className={`team-section bg-lighter text-center pt-115 rpt-95 pb-90 rpb-70 ${teamClass}`}
      >
        <div className="container">
          <div className="section-title mb-55">
            <span className="sub-title">Ils font partis de l'aventure</span>
            <h2>L'equipe Sybe</h2>
            <p className="tw-text-gray-500">
              "Auditez aujourd'hui, protégez demain » telle est la mission de Sybe
              Solutions : éviter aux entreprises rentables de sombrer dans la
              faillite à la suite d'une cyberattaque (ransomwares, arrêt de la
              chaine de production, vol de données confidentielles, blocage des
              systèmes d’information, etc.). Sybe Solutions, s’inscrit comme
              facilitateur d'atteinte de cyber-objectifs.
            </p>
          </div>
          <div className="row">
            {(partial && (
              <>
                {TeamV4Data.slice(0, 4).map((team) => (
                  <div className="col-lg-3 col-sm-6" key={team.id}>
                    <SingleTeamV4 team={team} />
                  </div>
                ))}
                {TeamV4Data.slice(4, 6).map((team) => (
                  <div className="col-lg-3 col-sm-6" key={team.id}>
                    <SingleTeamV4 team={team} />
                  </div>
                ))}
              </>
            )) || (
              <>
                {TeamV4Data.map((team) => (
                  <div className="col-lg-4 col-sm-6" key={team.id}>
                    <SingleTeamV4 team={team} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamV4;
