const accordionData = [
  {
    title: "Qui est concerné ?",
    content:
      "Dès lors que les états membres auront adopté les textes de lois qui traduisent et ajoutent NIS à leur droit, toutes les entreprises situées dans un état membre.  Par exemple, une entreprise française sera soumise à la loi française qui ajoute NIS aux lois françaises. Une entreprise belge quant à elle, sera soumise au droit belge qui ajoute NIS aux lois belges.",
  },
  {
    title: "Qu’est ce que cela implique ?",
    content:
      "Pour les entreprises, cela signifie concrètement que l’état membre dans lequel il se situe va légiférer pour garantir à minima les standards de la directive européenne NIS.",
  },
  {
    title: "Pourquoi pré-évaluer sa mise en conformité NIS ?",
    content:
      "Même si NIS impose aux états de légiférer et pas d’obligations directes pour les entreprises, la directive donne les standards minimaux. Un audit SYBE NIS vous permet de faire un état de lieux de la mise en conformité de votre société et d’anticiper dès maintenant les points à améliorer pour atteindre les cyber objectifs minimaux européens contenu dans NIS. Réduisez les risques, la charge administrative et les coûts en anticipant votre cybersécurité !",
  },
];

export default accordionData;
