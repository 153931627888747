import React, { useState, useEffect } from "react";
import FooterV1 from "../library/FooterV1";
import { useParams } from "react-router-dom";
import TeamV4Data from '../library/TeamV4Data.json';
import SocialShare from '../library/SocialShare';

const Teammember = () => {
    const { id } = useParams();
    const [member, setMember] = useState(null);

    useEffect(() => {
        const selectedMember = TeamV4Data.find(member => member.id === parseInt(id));
        if (selectedMember) {
            setMember(selectedMember);
        }
    }, [id]);

    return (
        <>
            {member && (
                <section className="team-profile py-120 rpy-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="team-profile-image rmb-45">
                                    <img src={`/assets/images/team/${member.thumb}`} alt="Team Profile" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="team-profile-content ml-40 rml-0">
                                    <h2 className="name">{member.name}</h2>
                                    <span className="designation">{member.title}</span>
                                    <p>{member.description}</p>
                                    <h3>Fonction</h3>
                                    <p>{member.designation}</p>
                                    {member.vision && (
                                        <>
                                            <h3>Vision</h3>
                                            <p>{member.vision}</p>
                                        </>
                                    )}
                                    {member.citation && (
                                        <>
                                            <h3>Citation</h3>
                                            <p>{member.citation}</p>
                                        </>
                                    )}
                                    <div className="row my-30">
                                        <div className="col-sm-6">
                                            <div className="service-normal style-two">
                                                <div className="icon">
                                                    <i className="flaticon flaticon-computer"></i>
                                                </div>
                                                <h6>{member.function1}</h6>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="service-normal style-two">
                                                <div className="icon">
                                                    <i className="flaticon flaticon-design-process"></i>
                                                </div>
                                                <h6>{member.function2}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="social-style-two mt-15">
                                        <SocialShare linkedinUrl={member.linkedinUrl}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            <FooterV1 />
        </>
    );
};

export default Teammember;
