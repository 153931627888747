import React from "react";
import { Link } from "react-router-dom";
import { Linkedin } from "lucide-react";

const FooterV1 = () => {
  const handleEmail = (event) => {
    event.preventDefault();
    event.target.reset();
  };

  const openPdf = () => {
    window.open("../assets/pdc.pdf", "_blank");
  };
  const openPdfCGU = () => {
    window.open("../assets/CGU-22042024.pdf", "_blank");
  };
  const openPdfCGV = () => {
    window.open("../assets/CGV-30042024.pdf", "_blank");
  };

  return (
    <>
      <footer className="main-footer footer-one text-white">
        <div
          className="footer-widget-area bgs-cover pt-100 pb-50"
          style={{ backgroundImage: "" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget about-widget">
                  <div className="footer-logo mb-35">
                    <Link to="/#">
                      <img
                        src="/assets/images/logos/logo_white.png"
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="text">
                  Auditez aujourd’hui, protégez demain
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="footer-widget link-widget ml-20 rml-0">
                  <h4 className="footer-title">Liens</h4>
                  <ul className="list-style-two">
                    <li>
                      <Link to="/propos">Mentions légales</Link>
                    </li>
                    <li>
                      <Link onClick={openPdf}>
                        Politique de Confidentialité
                      </Link>
                    </li>
                    <li>
                      <Link onClick={openPdfCGU}>
                        CGU
                      </Link>
                    </li>
                    <li>
                      <Link onClick={openPdfCGV}>
                        CGV
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget contact-widget mr-30 rmr-0">
                  <h4 className="footer-title">Contacts</h4>
                  <ul className="list-style-two">
                    <li>
                      <i className="fas fa-map-marker-alt"></i>Sybe Solutions
                      SAS
                      <br />
                      c/o Pôle de l’entrepreneuriat, 50 Rue Gustave Eiffel
                      <br />
                      01630 Saint-Genis-Pouilly, France
                    </li>
                    <li>
                      <i className="fas fa-clock"></i> Lun-Ven 8:00 - 18:00
                    </li>
                    <li>
                      <i className="fas fa-phone-alt"></i>{" "}
                      <Link to="tel:+33631392681">
                        +33 6 31 39 26 81 / +41 78 772 11 82
                      </Link>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>{" "}
                      <Link to="mailto:info@sybe-solutions.com">
                        info@sybe-solutions.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="footer-widget newsletter-widget">
                  <h4 className="footer-title">Newsletter</h4>
                  <p>
                    Rejoignez notre newsletter pour les dernières actualités et
                    offres spéciales.
                  </p>
                  <form onSubmit={handleEmail}>
                    <input
                      type="email"
                      name="EMAIL"
                      placeholder="Votre adresse email"
                      autoComplete="off"
                      required
                    />
                    <button value="submit">
                      <i className="fa fa-location-arrow"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area bg-blue">
          <div className="container">
            <div className="copyright-inner pt-15">
              <div className="social-style-one mb-10">
                <Link
                  to={"https://www.linkedin.com/company/sybe-solutions/"}
                  target="_blank"
                >
                  <Linkedin />
                </Link>
              </div>
              <p>&copy; {new Date().getFullYear()} Sybe All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterV1;
