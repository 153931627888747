const accordionData = [
    { title: "Comment savoir si on est concerné par NIS ?", content: "Vous êtes concerné par la directive NIS si vous appartenez à un secteur jugé essentiel ou important pour la société et l’économie.Tel que l'énergie, la santé, les transports, les banques, les fournisseurs de services numériques, la gestion des déchets, la recherche, les services de distribution d'eau, et les infrastructures de télécommunications.Si vous êtes une entreprise opérant dans ces secteurs, vous devez vous conformer aux exigences de la directive NIS. Pour plus de précisions, vous pouvez consulter les documents réglementaires [ANSSI] ou contacter votre expert en cybersécurité pour une évaluation de votre situation. Les hackers ne font pas de discrimination." },
    { title: "Pourquoi la cybersécurité peut impacter l'e-réputation de l'entreprise ?", content: "La cybersécurité est indispensable pour l’e-réputation de votre entreprise. Une cyberattaque peut exposer des données sensibles, entraînant une perte de confiance des clients et partenaires. Les violations de données sont souvent largement médiatisées, ce qui peut ternir l'image de votre entreprise et dissuader de futurs clients de faire affaire avec vous. Maintenir une cybersécurité maîtrisée montre que vous prenez au sérieux la protection des informations, renforçant ainsi la confiance et la fidélité." },
    {
        title: "Comment auditer et protéger son entreprise contre les cybermenaces ?",
        content: (
          <div className="tw-flex tw-flex-col">
            Il est important de dresser un état des lieux pour anticiper les coûts et les procédures en cas de mise en conformité conséquente.
            <ul className="tw-flex tw-flex-col tw-gap-4 tw-mt-4">
              <li><b>Réaliser un audit de sécurité </b>: Nos experts effectuent une évaluation approfondie de vos systèmes, identifient les vulnérabilités et proposent des solutions spécifiques pour les corriger. Cet audit fournit une analyse claire et détaillée pour renforcer votre sécurité.</li>
              <li><b>Mettre à jour vos logiciels </b>: Nous vous aidons à maintenir tous vos logiciels et systèmes à jour avec les derniers correctifs de sécurité, réduisant ainsi les risques de vulnérabilités exploitables par les cybercriminels.</li>
              <li><b>Former votre personnel </b>: Sybe Solutions offre des programmes de formation et de sensibilisation adaptés à tous les niveaux de compétences. En éduquant votre personnel, vous renforcez la première ligne de défense contre les cybermenaces.</li>
              <li><b>Implémenter des mesures de protection </b>: Nous vous conseillons sur les meilleures solutions de sécurité, telles que les pare-feu, les systèmes de détection d'intrusion, et les solutions antivirus, pour protéger efficacement vos réseaux et vos systèmes.</li>
              <li><b>Effectuer des sauvegardes régulières </b>: Nous vous aidons à mettre en place des stratégies de sauvegarde régulières de vos données critiques. En cas d'attaque, cela vous permet de restaurer rapidement vos systèmes sans céder aux demandes de rançon.</li>
              <li><b>Développer un plan de réponse aux incidents  </b>: Nos consultants collaborent avec vous pour élaborer et tester régulièrement un plan de réponse aux incidents de cybersécurité, incluant des procédures claires pour détecter, évaluer et répondre aux cyberattaques.</li>
            </ul>
          </div>
        ),
      },
  ];
  
  export default accordionData;
  