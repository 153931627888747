import React from 'react';
import {Link} from "react-router-dom";
import {ShieldCheck, Speech, HandHelping, NotebookPen} from "lucide-react";

const SingleServiceV1 = ({ service }) => {
    const { icon, title, text, icon2, title2, text2, subtitle } = service;
     // Objet de correspondance entre les noms d'icônes du JSON et les composants Lucide React
     const iconComponents = {
        ShieldCheck: ShieldCheck,
        Speech: Speech,
        HandHelping: HandHelping,
        NotebookPen: NotebookPen
        
    };

    const IconComponent = iconComponents[icon];
    return (
        <>

                <div className="service-box fadeInUp" >
                    <div className="service-normal tw-border-solid tw-border tw-border-light-grey" style={{ height: '470px' }}>
                        <div className="icon tw-mb-4 tw-flex tw-flex-col">
                        <IconComponent className="tw-text-main-blue tw-h-12 tw-w-12 " />
                        <span className='tw-text-main-blue tw-font-bold'>
                            {subtitle}
                        </span>
                          
                        </div>
                        <h6 style={{fontWeight: 600}}>{title}</h6>
                        <p>{text}</p>
                        <Link to="/solutions" className="btn-circle" style={{ position: 'absolute', bottom: '16px', left:0, right:0, margin:'0 auto' }}>
                            <div className='circle pulse'></div>
                        </Link>
                    </div>
                    <div className="service-hover bg-blue text-white">
                        <h3>{title2}</h3>
                        <p>{text2}</p>
                        <Link to="/solutions" className="theme-btn">Apprendre plus</Link>
                    </div>
                </div>

        </>
    );
};

export default SingleServiceV1;