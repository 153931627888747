module.exports = global.setpassform = {
    form:{
        password1:{
            value:'',
            required:true,
            type:'password',
            err_message:{
                en:'Password does not meet the criterias.'
            }
        },
        password2:{
            value:'',
            required:false,
            type:'password',
            err_message:{
                en:'Password does not match'
            }
        }
    },
    formsuccess_message: {
        en: 'Communicating with server'
    },
    server_error_message: {
        en: 'Username and password does not match',
    }
}