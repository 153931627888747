import React from "react";
import FooterV1 from "../library/FooterV1";
import { Link } from "react-router-dom";
import Accordion from "../library/accordion";
import accordionData from "../library/accordionDataCertif";
import accordionDataNis from "../library/accordionDataNis2";
import LucideNotebook from "lucide-react";
const Cybercompliance = () => {
  return (
    <>
      <section className="gallery-section-two overlay pt-40 rpt-95 pb-40 rpb-65 px-60 rpx-0 tw-mb-10">
        <div className="container-fluid">
          <div className="section-title text-center">
            <h2>Sybercompliance</h2>
          </div>
          <div className="row text-white"></div>
        </div>
      </section>
      <section className="hero-section-seven z-1 rel pt-10 tw-mb-20 tw-py-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="hero-content-seven pt-10 pb-50">
                <div className="section-title mb-30">
                  <span className="sub-title">
                    Comment garantir la conformité juridique de son entreprise
                    en matière de cybersécurité?
                  </span>
                  <h2 className="wow fadeInUp delay-0-4s mt-20">
                    Les règles concernant la cybersécurité sont en train
                    d’émerger.
                  </h2>
                </div>
                <p className="wow fadeInUp delay-0-6s">
                  La cybersécurité est un enjeu majeur d’hier, d’aujourd’hui et
                  de demain. Il est essentiel de savoir où en est son entreprise
                  pour anticiper ses obligations et les coûts. Mais également
                  pour garantir un niveau de sécurité élevé lorsque l’on conduit
                  son activité.
                </p>
                <div className="hero-btn mt-35">
                  <Link to="/solutions" className="theme-btn">
                    Nos solutions
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-right-part col-lg-5">
          <img className="tw-rounded-xl" src="/assets/images/eu.jpg" alt="Hero" />
          <div className="data-item one">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-book-type"
            >
              <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
              <path d="M16 8V6H8v2" />
              <path d="M12 6v7" />
              <path d="M10 13h4" />
            </svg>
            <div className="data-item-content">
              <h4>ISO 27001</h4>
            </div>
          </div>
          <div className="data-item two">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-package-check"
            >
              <path d="m16 16 2 2 4-4" />
              <path d="M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14" />
              <path d="m7.5 4.27 9 5.15" />
              <polyline points="3.29 7 12 12 20.71 7" />
              <line x1="12" x2="12" y1="22" y2="12" />
            </svg>
            <div className="data-item-content">
              <h4>NIS 2</h4>
            </div>
          </div>
          <div className="data-item three">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-notebook-text"
            >
              <path d="M2 6h4" />
              <path d="M2 10h4" />
              <path d="M2 14h4" />
              <path d="M2 18h4" />
              <rect width="16" height="20" x="4" y="2" rx="2" />
              <path d="M9.5 8h5" />
              <path d="M9.5 12H16" />
              <path d="M9.5 16H14" />
            </svg>
            <div className="data-item-content">
              <h4>Directive européenne</h4>
            </div>
          </div>
        </div>
      </section>
      <section className="about-seven rel z-1 rpt-30 tw-py-20 tw-bg-light-blue">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="about-seven-image rmb-60">
                <img src="/assets/images/iso.png" alt="About" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-seven-content">
                <div className="section-title mb-30">
                  <span className="sub-title">
                    Qu’est ce que l’ISO ? Une organisation internationale
                  </span>
                  <h2>Focus sur le cadre de la norme ISO 27001</h2>
                </div>
                <p>
                  L’ISO (International Standardization Organization ou
                  Organisation internationale de normalisation) est une
                  organisation internationale non gouvernementale et
                  indépendante qui se situe à Genève.
                </p>
                <p>
                  L'ISO édicte les normes dites “ISO” pour améliorer les
                  pratiques dans un domaine. L’organisation internationale de
                  normalisation vise à élaborer des normes internationales
                  d’application volontaire. Ces normes sont appelées les normes
                  “ISO”. La norme ISO 27001 met en place des règles auxquelles
                  les entreprises se soumettent volontairement concernant les
                  systèmes de management de la sécurité de l’information (SMSI).
                  C’est à dire que la norme ISO 27001 édicte des règles
                  auxquelles on se soumet volontairement et qui permettent de
                  gérer les risques liés à la sécurité des données au sein de
                  votre entreprise.
                </p>

                <div className="hero-btn mt-35">
                  <Link to="/solutions" className="theme-btn">
                    Identification des vulnérabilités
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="circle-drop"></div>
      </section>
      <section className="services-seven rel text-center rpy-100 tw-my-20">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title mb-75">
                <span className="sub-title">
                  Qu’est ce qu’une certification ISO ?
                </span>
                <h2>La certification ISO 27001</h2>
                <p className="tw-text-gray-400">
                  Elle permet pour une entreprise de démontrer les engagements
                  qu’elle prend en matière de sécurité des données. Des
                  organismes d’accréditation indépendant délivre les
                  certifications
                </p>
              </div>
            </div>
          </div>
          <Accordion accordionData={accordionData} />

          <div className="hero-btn mt-35">
            <Link to="/solutions" className="theme-btn">
              Explorer nos solutions
            </Link>
          </div>
        </div>
      </section>
      <section className="solution-place bgs-cover rel z-1 pb-50 rpb-100 tw-py-20  tw-bg-light-blue">
        <div className="container">
          <div className="row align-items-center gap-2">
            <div className="col-lg-6">
              <div className="solution-place-content rmb-60">
                <div className="section-title mb-30">
                  <span className="sub-title">
                    Qu’est ce que contient concrètement NIS 2 ?
                  </span>
                  <h2>
                    Focus sur le cadre règlementaire de NIS 2 et du droit de
                    l’Union européenne
                  </h2>
                </div>
                <p className="tw-text-gray-400">
                  La directive NIS 2 pose un socle commun de principes et de
                  procédures en matière de cybersécurité que les états membres
                  doivent traduire dans leur droit national et pour lesquelles
                  ils disposent d’une marge de manœuvre pour la rédaction des
                  lois.
                </p>
                <div className="">
                  <img className="tw-rounded-xl" src="/assets/images/eu1.jpg" alt="About" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Accordion accordionData={accordionDataNis} bgColor={"white"} />
            </div>
          </div>
        </div>
        <div className="circle-drop"></div>
      </section>
      <section className="services-seven rel text-center py-50 rpy-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title mb-75">
                <span className="sub-title">
                  Qu’est ce qu’une directive européenne ?
                </span>
                <h2>Focus sur le droit de l’Union européenne</h2>
                <p>
                  L’Union européenne a récemment révisé son cadre légal.
                  Auparavant, c’est la directive NIS 2 qui réglait le cadre des
                  exigences en matière de cybersécurité.
                </p>
                <p>
                  Désormais, c’est la directive NIS 2. La directive européenne NIS 2
                  est le prolongement de la directive européenne NIS 2 1. Elle
                  étend son champ d’application pour couvrir plus largement le
                  tissu économique sur le marché européen et protéger face aux
                  risques de cyber attaques.
                </p>
                <p>
                  Une directive européenne est un texte qui oblige les états
                  membres de l’Union européenne à adopter un standard minimal de
                  protection légal commun pour les citoyens européens.
                </p>
                <p>
                  C’est à dire que les états membre doivent adopter des lois
                  similaires à propos d’un domaine précis. On appelle cela une
                  “harmonisation” du droit.
                </p>
                <p>
                  Dans le cadre d’une directive l’harmonisation est partielle,
                  c’est à dire que chaque état membre a une marge de manœuvre
                  dans la manière d’appliquer les standards.
                </p>
              </div>
            </div>
          </div>
          <div className="hero-btn mt-35">
            <Link to="/solutions" className="theme-btn">
              Explorer les solutions
            </Link>
          </div>
        </div>
      </section>
      <section className="about-seven rel z-1 rpt-30 tw-py-20 tw-bg-light-blue">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="about-seven-image rmb-60">
                <img className="tw-rounded-xl" src="/assets/images/data4.jpg" alt="About" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-seven-content">
                <div className="section-title mb-30">
                  <span className="sub-title">
                    Pourquoi faut-il dès aujourd’hui se pencher sur les
                    questions de cybersécurité ?
                  </span>
                  <h2>
                    Focus sur l’importance du risk management dans le domaine de
                    la cybersécurité
                  </h2>
                </div>
                <p>
                  Dans l'ère numérique d'aujourd'hui, la sécurité informatique
                  ne consiste pas seulement à verrouiller les portes. C'est
                  aussi à prévoir les menaces et à les contrer avant qu'elles ne
                  surviennent. Le Risk Management en cybersécurité est la clé
                  pour protéger nos données et notre avenir numérique. Il permet
                  d’anticiper les crises et de s’y préparer.
                </p>
                <span className="sub-title">
                  Qu’est ce que SYBE fait pour vous ?
                </span>
                <p>
                  Les audits sont des outils essentiels pour dresser un bilan.
                  Ce bilan doit être accessible à tous, c’est pourquoi SYBE
                  Solutions propose des audits en ligne pour évaluer les points
                  d’exposition au risque en vue de les réduire.
                </p>
                <div className="hero-btn mt-35">
                  <Link to="/solutions" className="theme-btn">
                    Identifier des vulnérabilités
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-py-20 tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center">
        <div className="tw-container tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-items-center tw-w-full">
          <h3 className="tw-text-2xl">
            Des questions concernant nos solutions ?
          </h3>
          <div className="tw-flex tw-flex-row tw-gap-10">
            <div className="hero-btn fade-in-up">
              <Link to="/solutions" className="theme-btn">
                Découvrir nos solutions
              </Link>
            </div>
            <div className="hero-btn fade-in-up">
              <Link to="/contact" className="theme-btn">
                Nous contacter
              </Link>
            </div>
          </div>
        </div>
      </section>

      <FooterV1 />
    </>
  );
};
export default Cybercompliance;
