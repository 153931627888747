import React, { Component } from "react";
import functions from "../modules/functions";
import {restconnect} from "../modules/restconnect";
import FooterV1 from "../library/FooterV1";

let restcount = 0;
class Confirm extends Component{
    constructor(props) {
        super(props);
        this.state = {
            message: "Please wait",
            loading: true,
            id: functions.getLastPartOfUrl(),
            mounted: false // Add a flag to track if the component is mounted
        };
    }

    componentDidMount() {
        if (restcount === 0) { // Check if the component is still mounted before updating state
            restcount++;
            restconnect({"task": "confirmemail", "token": this.state.id}, "POST").then(r => {
                if (r.hasOwnProperty("error")) {
                    this.setState({ message: "This validation link are no longer valid" });
                } else {
                    this.setState({ message: "Email have been verified, please wait while redirecting to login" });
                     setTimeout(() => {
                         window.location.href = functions.getLocationUrl() + "/authenticate";
                     }, 3000);
                }
            });
        }
    }

    componentWillUnmount() {
        this.setState({ mounted: false }); // Set the mounted flag to false when the component unmounts
    }
    render() {
        return (
            <>
                <section className="contact_page">
                    <div className="container ">
                        <div className="row">
                            <div className="col-12 mt-sm-5 mt-4">
                                <div className="row col-12 justify-content-center">
                                    <div className="col-9">
                                        <h2>Confirming Email</h2>
                                        <br/>
                                    </div>
                                    <div id="contact-message" className="col-lg-12 col-sm-12">
                                        {this.state.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterV1/>
                </section>
            </>
        );
    }
}

export default Confirm