import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Legend } from "recharts";

ChartJS.register(ArcElement, Tooltip);

export default function ScoreDonut({score}) {


  // Déterminer la couleur du segment en fonction du score
   // Déterminer la couleur du segment en fonction du score
   let color;
   if (score <= 25) {
     color = ["#064cbd", "#f4f7fe", "#f4f7fe", "#f4f7fe"]; // Segment 1 en bleu, le reste en gris
   } else if (score >= 26 && score < 50) {
     color = ["#f4f7fe", "#064cbd", "#f4f7fe", "#f4f7fe"]; // Segment 2 en bleu, le reste en gris
   } else if (score >= 50 && score < 75) {
     color = ["#f4f7fe", "#f4f7fe", "#064cbd", "#f4f7fe"]; // Segment 3 en bleu, le reste en gris
  } else if (score >= 75) {
    color = ["#f4f7fe","#f4f7fe", "#f4f7fe", "#064cbd"]; // Segment 3 en bleu, le reste en gris
  } else {
        color = ["#f4f7fe","#f4f7fe", "#f4f7fe", "#f4f7fe"]; // Segment 4 en bleu, le reste en gris
  }

  let data = [
    {
    
      value: 25,
      cutout: "très faible",
      
    },
    {
      value: 25,
      cutout: "faible",
    },
    {
      value: 25,
      cutout: "moyen",
    },
    {
      value: 25,
      cutout: "élevé",

    }
  ];

  const options = {
    plugins: {
      responsive: true,
      legend: {
        display: false,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return finalData.labels[context.dataIndex];
          }
        }
      },
    },
  };

   

  const finalData = {
    labels: ["Très faible","Faible", "Moyen", "Élevé"],
    datasets: [
      {
        data: [25, 25, 25, 25],
        backgroundColor: color,
        borderColor: 'white',
        hoverBorderColor: 'white',
        borderWidth: 5,
        circumference: 180,
        cutout: 70,
        rotation: -90,
        
      },
    ],
  };

  return <Doughnut data={finalData} options={options} />;
}
