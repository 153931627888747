import React, { useEffect, useState } from "react";
import { restconnect } from "../modules/restconnect";
import functions from '../modules/functions.js';


function Usertable() {
    const f = functions;
    const [users, setUsers] = useState([]);
    const [purchases, setPurchases] = useState([]);

    useEffect(() => {
        if (sessionStorage.getItem('isLoggedIn') !== 'true') {
          //  window.location.href = f.getLocationUrl() + '/authenticate';
          //  return;
        }

        let param = { 'task': 'getusers' };
        restconnect(param, 'POST').then(r => {
            r.forEach(user => {
                Object.keys(user).forEach(key => {
                    if (typeof user[key] === 'string') {
                        user[key] = user[key].replace(/'/g, '');  // Remove single quotes from all string fields
                    }
                });
            });
            setUsers(r);
        });
    }, []);

    useEffect(() => {
        let param = { 'task': 'getpurchases' };
        restconnect(param, 'POST').then(r => {
            setPurchases(r);
        });
    }, []);

    const handleEditClick = (user) => {
        let param = { 'task': 'login', 'email': user.email, 'pass': user.pass };
        restconnect(param, 'PATCH').then(r => {
            if (r.hasOwnProperty('user')) {
                if (r['user'].hasOwnProperty('error')) {
                } else {
                    f.storeObject('user', r['user']);
                    f.storeObject('products', r['products']);
                    f.createSessionObject(r['user']['session']);
                    setTimeout(() => {
                        window.location.href = f.getLocationUrl() + '/summary';
                    }, 1000);
                }
            }
        });

    };


    const getUserNameById = (userId) => {
        const user = users.find(user => user.id === userId);
        return user ? `${user.firstname} ${user.lastname}` : 'Unknown User';
    };

    const formatPrice = (price) => {
        return `${(price / 100).toFixed(2)} EUR`;
    };

    const styles = {
        tableContainer: {
            width: 'calc(100% - 40px)',
            margin: '20px',
            fontFamily: 'Arial, sans-serif',
            textAlign: 'center',
        },
        header: {
            marginBottom: '20px',
            fontSize: '24px',
            color: '#333',
        },
        table: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        th: {
            padding: '12px 15px',
            border: '1px solid #ddd',
            backgroundColor: '#f4f4f4',
            color: '#333',
            fontWeight: 'bold',
        },
        td: {
            padding: '12px 15px',
            border: '1px solid #ddd',
        },
        trEven: {
            backgroundColor: '#f9f9f9',
        },
        trHover: {
            backgroundColor: '#f1f1f1',
        },
        editFormContainer: {
            margin: '20px 0',
            padding: '20px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
        },
        input: {
            display: 'block',
            margin: '10px 0',
            padding: '10px',
            width: '100%',
            borderRadius: '4px',
            border: '1px solid #ddd',
        },
        button: {
            margin: '10px 5px',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            backgroundColor: '#28a745',
            color: '#fff',
            cursor: 'pointer',
        },
        cancelButton: {
            backgroundColor: '#dc3545',
        },
    };

    return (
        <div style={styles.tableContainer}>
            <h2 style={styles.header}>Users</h2>
            <table style={styles.table}>
                <thead>
                <tr>
                    <th style={styles.th}>ID</th>
                    <th style={styles.th}>First Name</th>
                    <th style={styles.th}>Last Name</th>
                    <th style={styles.th}>Email</th>
                    <th style={styles.th}>Phone</th>
                    <th style={styles.th}>Edit</th>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                    <tr
                        key={index}
                        style={index % 2 === 0 ? styles.trEven : null}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.trHover.backgroundColor)}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? styles.trEven.backgroundColor : 'transparent')}
                    >
                        <td style={styles.td}>{user.id}</td>
                        <td style={styles.td}>{user.firstname}</td>
                        <td style={styles.td}>{user.lastname}</td>
                        <td style={styles.td}>{user.email}</td>
                        <td style={styles.td}>{user.phone}</td>
                        <td style={styles.td}>
                            <button style={styles.button} onClick={() => handleEditClick(user)}>Profile</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <h2 style={styles.header}>Purchases</h2>
            <table style={styles.table}>
                <thead>
                <tr>
                    <th style={styles.th}>Date</th>
                    <th style={styles.th}>Nom</th>
                    <th style={styles.th}>Payé</th>
                    <th style={styles.th}>Carte</th>
                    <th style={styles.th}>Ville</th>
                    <th style={styles.th}>Pays</th>
                </tr>
                </thead>
                <tbody>
                {purchases.map((purchase, index) => (
                    <tr
                        key={index}
                        style={index % 2 === 0 ? styles.trEven : null}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = styles.trHover.backgroundColor)}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? styles.trEven.backgroundColor : 'transparent')}
                    >
                        <td style={styles.td}>{purchase.purchasedate}</td>
                        <td style={styles.td}>{getUserNameById(purchase.userid)}</td>
                        <td style={styles.td}>{formatPrice(purchase.productprice)}</td>
                        <td style={styles.td}>{purchase.cardbrand}</td>
                        <td style={styles.td}>{purchase.adresscity}</td>
                        <td style={styles.td}>{purchase.addresscountry}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default Usertable;
