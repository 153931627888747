import React from "react";
import FooterV1 from "../library/FooterV1";
import TeamV4 from "../library/TeamV4";

const About = () => {
    return (
        <>
            <TeamV4 teamClass="pb-200" partial={false} />
            <FooterV1/>
        </>
    );
}

export default About;