import React from "react";
import { Link } from "react-router-dom";
import "../../src/styles.css";
import { ScanEye } from "lucide-react";

const BlogSingle = ({ gallery }) => {
  const { thumb, title, id, previewText } = gallery;
  return (
    <>
      <Link to={`/blogdetails/${id}`} className="tw-w-full tw-group">
        <div className="tw-p-6 tw-bg-white tw-rounded-xl tw-border-solid tw-border tw-flex-auto tw-flex tw-flex-col tw-gap-6 tw-relative tw-border-light-grey hover:tw-shadow">
        <div className="tw-flex tw-flex-col tw-justify-between tw-gap-4">
              <span className="tw-font-semibold tw-text-2xl">{title}</span>
              
            </div>
          <div className="tw-flex tw-max-h-52 tw-overflow-hidden tw-w-full tw-rounded-xl group-hover:tw-translate-y-1 tw-transition-all tw-200 ">
            <img
              className="tw-object-cover "
              src={`assets/images/services/${thumb}`}
              alt=""
            />
          </div>
         <div className="tw-flex tw-justify-between tw-h-full ">
            <div className="tw-flex tw-flex-col tw-justify-between tw-gap-4">
              <p className="tw-text-gray-400">{previewText}</p>
            </div>
          </div>

          <div className="tw-flex tw-absolute tw-right-3 tw-bottom-3 group-hover:tw-bottom-4 tw-transition-all tw-200">
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-end tw-justify-center">
              <span className="tw-text-3xl tw-font-bold tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center tw-rounded-xl tw-bg-light-grey">
                <ScanEye className="tw-text-blue-500" />
              </span>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default BlogSingle;
