import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function BarChart({scores}) {
    const options = {
        plugins: {
            title: {
                display: false,
                text: 'Chart.js Bar Chart - Stacked',
            },
            legend: {
                display: true,
                position: 'bottom',
            },
        },
        responsive: true,
        aspectRaio: 1,
        scales: {
            x: {
                stacked: false,
                min: 0,
                max:100,
            },
            y: {
                stacked: false,
            },
        },
    };

    const labels = [''];

    const data = {
        labels,
        datasets: [
            {
                label: 'Materiel et logiciel',
                data: [scores.cat1],
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: 'Sauvegarde systeme',
                data: [scores.cat2],
                backgroundColor: 'rgb(75, 192, 192)',
            },
            {
                label: 'Sécurité',
                data: [scores.cat3],
                backgroundColor: 'rgb(53, 162, 235)',
            },
            {
                label: 'Protection',
                data: [scores.cat4],
                backgroundColor: 'rgb(53, 15, 235)',
            }
        ],
    };


    return <Bar options={options} data={data} />;
}