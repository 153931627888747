import React from 'react';
import CountUp from 'react-countup';
const SingleCounterV1 = ({ counter }) => {
    const { start, end, title, type } = counter;

    return (
        <>
            <div className="success-item">
                <span className="count-text plus">
                    <CountUp start={start} end={end} duration={5} />
                    {type? type : ''}
                </span>
                <p className='fs-2'>{title}</p>
            </div>
        </>
    );
};

export default SingleCounterV1;