import React from 'react'

const progressbar = ({bgcolor,progress,height}) => {

    const textColor = progress < 10 ? 'transparent' : 'white';

    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: 10,
        margin: 10,
        border: '1px solid rgb(230,230,230'
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius:10,
        textAlign: 'right'
    }

    const progresstext = {
        padding: 10,
        color: textColor,
        fontWeight: 500
    }

    return (
        <div style={Parentdiv}>
            <div style={Childdiv}>
                <span style={progresstext}>{`${progress}%`}</span>
            </div>
        </div>
    )
}

export default progressbar;