// components/SurveyPDF.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { restconnect } from '../modules/restconnect';
import { jsPDF } from 'jspdf';
import logo from '../assets/images/logos/logo-web-transparent.png';

const SurveyPDF = () => {
    const { id } = useParams();
    const [completedSurveys, setCompletedSurveys] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [surveyName, setSurveyName] = useState('');
    const [dataError, setDataError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const surveyParam = { task: "get_awailable_surveys", uid: 68 };
                const surveyResponse = await restconnect(surveyParam, "POST");

                if (surveyResponse.hasOwnProperty("error")) {
                    setDataError('Error loading surveys');
                } else {
                    const complete = surveyResponse["csurveys"];
                    setCompletedSurveys(complete);
                    console.log("Completed surveys:", complete);
                    if (complete.length > 0) {
                        const questionParam = { 'task': 'get_questions', 'surveyid': 1 };
                        const questionResponse = await restconnect(questionParam, 'POST');

                        if (questionResponse.hasOwnProperty('error')) {
                            setDataError('Error loading survey');
                        } else {
                            setQuestions(questionResponse);
                            console.log("Questions:", questionResponse);
                            setSurveyName(questionResponse[0]['name'] || 'Survey'); // Ensure survey name is set if available
                        }
                    }
                }
            } catch (error) {
                setDataError('Error fetching data');
            }
        };

        fetchData();
    }, [id]);

    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFont('Helvetica');

        const pageHeight = doc.internal.pageSize.height;
        const margin = 10;
        const lineHeight = 7;
        const maxWidth = doc.internal.pageSize.width - margin * 2;
        let yOffset = 40;

        doc.addImage(logo, 'PNG', margin, 10, 50, 20); // Add the logo to the top left

        doc.setFontSize(14);
        doc.text(surveyName || "Survey", margin, yOffset);
        yOffset += 10;

        questions.forEach((question, index) => {
            if (yOffset + lineHeight > pageHeight) { // Check if space is available for the next question
                doc.addPage();
                yOffset = margin;
                doc.addImage(logo, 'PNG', margin, 10, 50, 20); // Add the logo to the top left of the new page
                yOffset += 30; // Adjust to the next position after the logo
            }

            const splitQuestion = doc.splitTextToSize(`${index + 1}. ${question.questiontext}`, maxWidth);
            doc.setFontSize(10);
            splitQuestion.forEach(line => {
                doc.text(line, margin, yOffset);
                yOffset += lineHeight;
                if (yOffset + lineHeight > pageHeight) {
                    doc.addPage();
                    yOffset = margin;
                    doc.addImage(logo, 'PNG', margin, 10, 50, 20); // Add the logo to the top left of the new page
                    yOffset += 30; // Adjust to the next position after the logo
                }
            });

            const completedSurvey = completedSurveys.find(s => s.surveyid === question.surveyid);
            if (completedSurvey) {
                const surveyResponse = JSON.parse(completedSurvey.surveyrespons);
                const response = surveyResponse.find(r => r.id === question.questionid);
                const selectedAnswers = response ? (Array.isArray(response.a) ? response.a : [response.a]) : [];

                console.log("Question ID:", question.questionid);
                console.log("Selected Answers:", selectedAnswers);

                if (question.answer) {
                    const answers = question.answer.split(', ');
                    answers.forEach(answer => {
                        if (yOffset + lineHeight > pageHeight) { // Check if space is available for the next answer
                            doc.addPage();
                            yOffset = margin;
                            doc.addImage(logo, 'PNG', margin, 10, 50, 20); // Add the logo to the top left of the new page
                            yOffset += 30; // Adjust to the next position after the logo
                        }

                        const isSelected = selectedAnswers.includes(answer.replace("'", "#"));
                        console.log("Answer:", answer, "Is Selected:", isSelected);

                        doc.setFontSize(10);
                        if (isSelected) {
                            doc.setTextColor(255, 0, 0); // Set selected answer color to red
                            doc.text(`- ${answer} (Selected)`, margin, yOffset);
                            doc.setTextColor(0, 0, 0); // Reset text color to black
                        } else {
                            doc.text(`- ${answer}`, margin, yOffset);
                        }
                        yOffset += lineHeight;
                    });
                }
            }

            yOffset += lineHeight; // Additional space between questions
        });

        doc.save('survey_results.pdf');
    };

    if (dataError) {
        return <div>Error: {dataError}</div>;
    }

    return (
        <div>
            <h1>{surveyName}</h1>
            <button onClick={generatePDF}>Generate PDF</button>
        </div>
    );
};

export default SurveyPDF;
