import React, { Component } from "react";
import { formaudit } from "../modules/formcheck";
import insertuserform from "../modules/insertuserform";
import { restconnect } from "../modules/restconnect";
import functions from "../modules/functions";
import { Link } from "react-router-dom";
import FooterV1 from "../library/FooterV1";

class Inscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback: "",
            location: functions.getLocationUrl(),
            showLogin: true,
            showContinue: false
        };
        this.audit = formaudit;
        this.uf = insertuserform;
    }

    inputChange = (event) => {
        this.uf.form[event.target.id].value = event.target.value;
    };

    onHandleSubmit = async (e) => {
        e.preventDefault();
        let formcheck = this.audit(this.uf);
        let emailmatch = this.uf.form.upass.value === this.uf.form.upass2.value;

        if (emailmatch && formcheck.success) {
            let form = formcheck.post;
            form['location'] = this.state.location;
            let params = { 'task': "insertuser", 'formdata': form };

            restconnect(params, "POST").then((r) => {
                if (r.hasOwnProperty('email')) {
                    if (r["email"].toString() === "true") {
                        this.setState({ feedback: 'Merci pour votre confiance.' })
                        window.location.href = functions.getLocationUrl() + "/inscribecomplete";
                    }
                } else {
                    this.setState({ feedback: 'Un utilisateur avec le même email est déjà enregistré' })
                }
            });

        } else {
            if (!emailmatch) {
                this.setState({ feedback: "Remplissez touts les champs svp." });
            } else {
                this.setState({ feedback: formcheck.message.en });
            }
        }

    };
    Stripetest = async (e) => {
        e.preventDefault();
        let formcheck = this.audit(this.uf);
        let emailmatch = this.uf.form.upass.value === this.uf.form.upass2.value;

        if (emailmatch && formcheck.success) {
            let form = formcheck.post;
            form['location'] = this.state.location;
            let params = { 'task': "insertuser", 'formdata': form };

            restconnect(params, "POST").then((r) => {
                if (r.hasOwnProperty('email')) {
                    if (r["email"].toString() === "true") {
                        this.setState({ feedback: 'Merci pour votre confiance.' })
                        window.location.href = functions.getLocationUrl() + "/inscribecomplete";
                    }
                } else {
                    this.setState({ feedback: 'Un utilisateur avec le même email est déjà enregistré' })
                }
            });
        } else {
            if (!emailmatch) {
                this.setState({ feedback: "Remplissez touts les champs svp." });
            } else {
                this.setState({ feedback: formcheck.message.en });
            }
        }
    };

    render() {
        return (
            <>
                <section className="contact_page">
                    <div className="container ">
                        <div className="row">
                            <div className="col-12 mt-sm-5 mt-4">
                                <div className="row col-12 justify-content-center">
                                    <div className="col-9">
                                        <h2>Inscrivez vous</h2>
                                        <br />
                                    </div>
                                    <div id="contact-message" className="col-lg-12 col-sm-12">&nbsp;</div>
                                    <div className="col-9" style={{ marginBottom: '20px' }}>
                                        <input
                                            id="ucompany"
                                            type="text"
                                            onChange={this.inputChange}
                                            placeholder="Société" />
                                    </div>
                                </div>
                                <div className="row col-12 justify-content-center" style={{ marginBottom: '20px' }}>
                                    <div className="gap-lg-4 gap-md-3 gap-sm-4 gap-3 col-9">
                                        <input
                                            id="utitle"
                                            type="email"
                                            onChange={this.inputChange}
                                            placeholder="Votre titre" />
                                    </div>
                                </div>

                                <div className="row col-12 justify-content-center" style={{ marginBottom: '20px' }}>
                                    <div className="d-flex gap-lg-4 gap-md-3 gap-sm-4 gap-3 col-9 d-inline-block flex-column flex-md-row tw-gap-6">
                                        <input id="ufirstname"
                                               type="text"
                                               onChange={this.inputChange}
                                               placeholder="Prénom"
                                               style={{ marginRight: '20px' }} />
                                        <input id="ulastname"
                                               type="text"
                                               onChange={this.inputChange}
                                               placeholder="Nom de famille"
                                               style={{ marginRight: '20px' }} />
                                        <input id="uphone"
                                               type="text"
                                               onChange={this.inputChange}
                                               placeholder="Mobile" />
                                    </div>
                                </div>
                                <div className="row col-12 justify-content-center" style={{ marginBottom: '20px' }}>
                                    <div className="col-9">
                                        <input id="uemail" className="col-12"
                                               type="email"
                                               onChange={this.inputChange}
                                               placeholder="Email" />
                                    </div>
                                </div>
                                <div className="row col-12 justify-content-center" style={{ marginBottom: '20px' }}>
                                    <div className="col-9">
                                        <p>Votre mot de passe doit contenir au moins 8 caractères, une lettre majuscule, une lettre minuscule, et un caractère spécial (!@#$%^&*).</p>
                                        <input id="upass"
                                               type="password"
                                               onChange={this.inputChange}
                                               placeholder="Votre mot de passe" required />
                                    </div>
                                </div>
                                <div className="row col-12 justify-content-center" style={{ marginBottom: '20px' }}>
                                    <div className="col-9">
                                        <input id="upass2"
                                               type="password"
                                               onChange={this.inputChange}
                                               placeholder="Repetez votre mot de passe" required />
                                    </div>
                                </div>
                                <div className="row col-12 justify-content-center mb-3" style={{ marginBottom: '20px' }}>
                                    <div className="col-9">
                                        {this.state.feedback}
                                    </div>
                                </div>

                                <div className="row col-12 justify-content-center" style={{ marginBottom: '20px' }}>
                                    {this.state.showLogin &&
                                        <div className="row col-9">
                                            <button type="submit" className="float-end nav-link theme-btn"
                                                    onClick={this.onHandleSubmit}>Se connecter
                                            </button>
                                            {/*<button type="submit" className="float-end nav-link theme-btn"
                                                    onClick={this.Stripetest}>Registre
                                            </button>*/}
                                        </div>
                                    }
                                    {this.state.showContinue &&
                                        <div className="row col-9">
                                            <Link to="/authenticate" className="float-end nav-link theme-btn"> Se connecter</Link>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterV1 />
                </section>
            </>
        );
    }
}

export default Inscribe;
