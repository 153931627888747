import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Linkedin } from "lucide-react";

const SingleTeamV4 = ({ team }) => {
  const { thumb, name, designation, id, linkedinUrl } = team;

  const navigate = useNavigate();

  const handleTeamMemberClick = () => {
    navigate(`/teammember/${id}`);
  };

  const handleLinkedinClick = (event) => {
    event.stopPropagation();
  };

  return (
      <>
        <div
            className="team-member-four tw-rounded-xl tw-border tw-border-solid tw-border-light-grey"
            style={{ height: "450px", cursor: "pointer" }}
            onClick={handleTeamMemberClick}
        >
          <div className="image">
            <img
                src={`/assets/images/team/${thumb}`}
                alt="Membre de l'équipe"
                style={{ width: "200px", height: "300px", objectFit: "cover" }}
            />
            <div className="social-style-two" onClick={handleLinkedinClick}>
              <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                <Linkedin />
              </a>
            </div>
          </div>
          <div className="member-designation">
            <h5>{name}</h5>
            <span>{designation}</span>
          </div>
        </div>
      </>
  );
};

export default SingleTeamV4;
