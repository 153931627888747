import React from "react";
import {
  ShieldCheck,
  TriangleAlert,
  Hourglass,
  HandCoins,
  Info,
} from "lucide-react";
import DonutGraphicCategories from "./donutGraphicCategories";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";



const SectionWrapper = ({ title, subtitle, icon, children }) => (
  <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-flex-auto tw-flex tw-flex-col">
    <div className="tw-flex tw-justify-between">
      <div className="tw-flex tw-flex-col">
        <span className="tw-font-semibold">{title}</span>
        <span className="">{subtitle}</span>
      </div>
      {icon}
    </div>
    {children}
  </div>
);

const ConformitySection = ({ score, resultText, iconClass }) => (
  <SectionWrapper
    title="Conformité"
    subtitle="Niveau de conformité."
    icon={<ShieldCheck className={iconClass} />}
  >
    <div className="tw-p-6 tw-relative tw-mx-auto tw-w-full tw-max-w-96">
      <DonutGraphicCategories score={score} resultats={resultText} />
    </div>
    <div className="tw-flex tw-flex-row tw-justify-between tw-gap-5">
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-end tw-justify-center">
        <div className="tw-flex tw-text-3xl tw-font-bold">
          <span className={iconClass}>{score}</span>/ 100
        </div>
        <span className="tw-text-2xl">de conformité</span>
      </div>
    </div>
  </SectionWrapper>
);

const RiskSection = ({ score, resultText, iconClass }) => (
  <SectionWrapper
    title="Risques"
    subtitle="Niveau de risque."
    icon={<TriangleAlert className={iconClass} />}
  >
    <div className="tw-p-6 tw-relative tw-mx-auto tw-w-full tw-max-w-96">
      <DonutGraphicCategories score={score} resultats={resultText} />
    </div>
    <div className="tw-flex tw-flex-row tw-justify-between tw-gap-5">
      <div className="tw-flex tw-flex-row tw-gap-2 tw-items-end tw-justify-center">
        <div className="tw-flex tw-text-3xl tw-font-bold">
          <span className={iconClass}>{score}</span>/ 100
        </div>
        <span className="tw-text-2xl">score de risque</span>
      </div>
    </div>
  </SectionWrapper>
);

const TimeSection = ({ hours }) => (
  <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-flex-auto tw-flex tw-flex-col">
    <div class="tw-flex tw-justify-between">
      <div class="tw-flex tw-flex-col">
        <div className="tw-flex tw-gap-2">
          <span class="tw-font-semibold">Temps humain</span>
          <Tooltip id="tooltip-time" />
              <a
                  data-tooltip-id="tooltip-time"
                  data-tooltip-content="Temps requis pour atteindre la conformité Sybe"
                  data-tooltip-place="top-start"
                >
                  <Info className="tw-h-4" />
                </a>
        </div>
        <span class="">Niveau de conformité.</span>
      </div>
      <Hourglass className="tw-text-blue-500" />
    </div>
    <div className="tw-flex tw-flex-row tw-justify-center tw-gap-5">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center">
        <span className="tw-text-6xl tw-font-bold">{hours}</span>
        <span className="tw-text-2xl">heures</span>
      </div>
    </div>
  </div>
);

const BudgetSection = ({ budget }) => (
  <SectionWrapper
    title="Budget"
    subtitle="Estimation du budget."
    icon={<HandCoins className="tw-text-blue-500" />}
  >
    <div className="tw-flex tw-flex-row tw-justify-center tw-gap-5">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center">
        <span className="tw-text-6xl tw-font-bold">{budget}</span>
        <span className="tw-text-2xl">€</span>
      </div>
    </div>
  </SectionWrapper>
);

const CallToActionSection = ({ title }) => (
  <div className="tw-p-3 tw-bg-main-blue tw-rounded-xl tw-border tw-flex-auto tw-flex tw-flex-col">
    <div className="tw-flex tw-w-full tw-justify-center tw-items-center tw-h-full">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center">
        <span className="tw-font-semibold tw-text-white tw-text-2xl tw-text-center">
          {title}
        </span>
        <Link to="https://meetings-eu1.hubspot.com/tom-bonnot" target="_blank">
          <button className="tw-bg-white tw-text-main-blue tw-py-2 tw-px-4 tw-rounded-lg tw-font-semibold tw-uppercase hover:tw-transition-all hover:tw-scale-105 tw-ease-in-out hover:tw-ease-in-out">
            Réserver
          </button>
        </Link>
      </div>
    </div>
  </div>
);

export {
  ConformitySection,
  RiskSection,
  TimeSection,
  BudgetSection,
  CallToActionSection,
};
