import React from "react";
import { Link } from "react-router-dom";
import Service1Data from "../library/ServiceV1Data.json";
import SingleServiceV1 from "../library/SingleServiceV1";
import SingleGalleryV2 from "../library/SingleGalleryV2";
import FooterV1 from "../library/FooterV1";
import CounterV1 from "../library/CounterV1";
import GalleryV2Data from "../library/GalleryV2Data.json";
import { useEffect, useState } from "react";

const Home = () => {

  const [showPopup, setShowPopup] = useState(false);
  const [accepted, setAccepted] = useState(0);

  useEffect(() => {
    // Check if the user has accepted the terms before
    const hasAccepted = localStorage.getItem('termsAccepted');
    if (!hasAccepted) {
      setShowPopup(false);
    }
  }, []);

  const handleAccept = () => {
    setAccepted(1);
    localStorage.setItem('termsAccepted', 'true');
    setShowPopup(false);
  };

  const handleLearnMore = () => {
    // Add logic to navigate to the privacy policy and terms of use page
   
      window.open("../assets/pdc.pdf", "_blank");
   
  };

  return (
    <>
    {showPopup && (
        <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-50 tw-overflow-y-auto tw-h-full tw-w-full tw-flex tw-justify-center tw-items-center tw-z-20">
          <div className="tw-relative tw-mx-auto tw-p-5 tw-border tw-w-96 tw-shadow-lg tw-rounded-md tw-bg-white">
            <p>
              Avant de continuer, veuillez prendre un moment pour consulter notre Politique de Confidentialité et nos Conditions Générales d’Utilisation du Site. En utilisant ce site web, vous acceptez les termes décrits dans notre Politique de Confidentialité et nos Conditions Générales d’Utilisation du Site. Votre vie privée et la sécurité de vos données sont importantes pour nous.
            </p>
            <div className="tw-mt-4 tw-flex tw-justify-center">
              <button
                className="tw-px-4 tw-py-2 tw-bg-main-blue tw-text-white tw-rounded tw-hover:bg-blue-600"
                onClick={handleAccept}
              >
                Accepter
              </button>
              <button
                className="tw-ml-2 tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-rounded tw-hover:bg-gray-600"
                onClick={handleLearnMore}
              >
                En savoir plus
              </button>
            </div>
          </div>
        </div>
      )}
      <section className="hero-section overlay bgs-cover pt-200 pb-150 tw-bg-main-blue ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-9">
              <div className="hero-content text-center text-white">
                <span className="sub-title d-block tw-italic">
                  Bienvenue chez Sybe Solutions <br></br> Votre partenaire de
                  confiance en matière de cybersécurité
                </span>
                <h1 className="mt-20 fade-in-up tw-text-6xl">
                  Auditez aujourd’hui, protégez demain
                </h1>
                <div className="hero-btn mt-35 fade-in-up">
                  <Link to="/contact" className="theme-btn">
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`about-section pt-120 rpt-100 has-bg tw-bg-light-blue`}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              
                <img
                  className="tw-rounded-xl"
                  src="/assets/images/about/ordinateurs.jpeg"
                  alt="About"
                />
              
            </div>
            <div className="col-lg-6">
              <div className={`about-content pr-70 rpr-0`}>
                <div className="section-title mb-35">
                  <h2>
                    Identifiez les vulnérabilités et les menaces avant qu'elles
                    ne deviennent un problème.
                  </h2>
                </div>
                <p className="tw-text-gray-400">
                  À une époque où les violations de données et les cyberattaques
                  sont plus courantes que jamais, la protection de votre
                  organisation n'est pas simplement un choix ; c'est une
                  nécessité.
                </p>
                <ul className="list-style-one mt-15">
                  <li>Cyber vulnérabilités</li>
                  <li className="tw-flex tw-items-center">
                    Formation des employés
                  </li>
                  <li>Conseil sur mesure</li>
                  <li>Veille règlementaire</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="fact-counter-inner br-10 px-15 pt-10 pb-10 mb-150 text-white text-center"
            style={{ backgroundColor: `#104cba` }}
          >
            <div className="row">
              <CounterV1 counterSpace="pt-10 rpt-10 pb-110 rpb-110" />
            </div>
          </div>
        </div>
      </section>
      <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
        <div className="container">
          <div className="row">
            {Service1Data.slice(0, 4).map((service) => (
              <div className="col-xl-3 col-sm-6" key={service.id}>
                <SingleServiceV1 service={service} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="gallery-section-two overlay pt-115 rpt-95 pb-85 rpb-65 px-60 rpx-0">
        <div className="container">
          <div className="section-title text-center mb-55">
            <span className="sub-title">Nos solutions</span>
            <h2>
              Avec Sybe solutions auditez et protégez votre forteresse numérique
            </h2>
          </div>
          <div className="tw-gap-4 tw-grid md:tw-grid-cols-2  ">
            <>
              {GalleryV2Data.map((gallery, index) => (
                <div
                  className={
                    index >= GalleryV2Data.length - 1 ? "md:tw-col-span-2" : ""
                  }
                  key={gallery.id}
                >
                  <SingleGalleryV2 gallery={gallery} />
                </div>
              ))}
            </>
          </div>
        </div>
      </section>
      <section className="tw-py-16">
        <div className="container">
          <div className="section-title text-center mb-55">
            <span className="sub-title">Ils nous font confiance</span>
            <h2>L'aventure à nos côtés</h2>
          </div>
          <div className="tw-gap-4 tw-grid md:tw-grid-cols-2  ">
            <>
              <img
                src="/assets/images/partners/rif.png"
                alt="RIF"
                className="tw-max-w-40 tw-mx-auto"
              />
              <img
                src="/assets/images/partners/pays-gex.png"
                alt="RIF"
                className="tw-max-w-80 tw-mx-auto"
              />
            </>
          </div>
        </div>
      </section>
      <FooterV1 />
    </>
  );
};

export default Home;
