import React from "react";
import {Link} from "react-router-dom";

const NavigateButton = ({url,buttonName}) => {

    return (
        <>
            <Link className="theme-btn" to={url}>
                {buttonName}
            </Link>
        </>

    )
}

export default NavigateButton;