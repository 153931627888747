import React from "react"

const Messagediv = ({message}) => {
    return (
        <div className="mb-3 mb-0 text-center" id="errormessage">
            {message}
        </div>
    );
}

export default Messagediv;