import React, {useEffect, useState} from "react";
import f from "../modules/functions";
import NavigateButton from "../library/navigateButton";



const Transactions = () => {

    const [user] = useState(f.getKeyInStorage("user"));
    const [transactions, setTransactions] = useState([]);
    const [showReciept, setShowReciept] = useState(false);
    const [cr, setCurrentReciept] = useState(null);

    useEffect(() => {
        setTransactions(user["purchases"]);
        // Code to execute when component mounts
    }, []);

    const changeReciept = (index) => {
        setShowReciept(true);
        setCurrentReciept(transactions[index]);
        // Code to execute when the button is clicked
    }

    return (<>
        <section className="featured-section bgs-cover">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-8">
                        <h3>Transactions</h3>
                        {(transactions.length > 0) &&
                            <table>
                                <tbody>
                                <tr>
                                    <td>Date</td>
                                    <td>
                                        <div style={{marginLeft: 20 + "px", marginRight: 20 + "px"}}>Product</div>
                                    </td>
                                    <td>Sum</td>
                                    <td>&nbsp;</td>
                                </tr>
                                {transactions.map((row, index) => {
                                    return (
                                        <tr key={"tr_" + index}>
                                            <td>{row["transactiondate"]}</td>
                                            <td>
                                                <div style={{
                                                    marginLeft: 20 + "px",
                                                    marginRight: 20 + "px"
                                                }}>{row["productname"]}</div>
                                            </td>
                                            <td> {"EUR " + row["payedprice"] / 100}</td>
                                            <td>
                                                <button className="theme-btn ml-20 btn-sm" type="button"
                                                        onClick={(() => changeReciept(index))}>Reciept
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td><NavigateButton url={"/summary"} buttonName={"Retour"}/></td>
                                </tr>
                                </tbody>
                            </table>}

                    </div>
                </div>
                {showReciept &&
                    <div className="row">
                        <div className="col-lg-10 col-md-8 mt-60">
                            <h3>Reciept / Transactions details</h3>
                            <br/>Date & time of purchase: { cr["transactiondate"] }
                        <br/>Product:  { cr["productname"].replace("#","'") }
                        <br/>Price: EUR {cr["payedprice"]/100}
                        <br/><br/>Billing:
                        <br/>Purchased by: { user["utitle"] + " " + user["ufirstname"] + " " + user["ulastname"] }
                        <br/>Company: {user["companyname"]}
                        <br/>Address: { cr["address"].replace("#","'") }
                        <br/>{ cr["zip"] + " " + cr["city"] }
                        <br/>{ cr["country"] }
                        <br/><br/>Credit Card Details:
                        <br/>Card Brand: { cr["cardtype"]}
                        <br/>Expire Year: { cr["expyear"]}
                        <br/>Last four digits: {cr["last4"]}
                    </div>
                </div>
                }
             </div>
        </section>
    </>);

}

export default Transactions;