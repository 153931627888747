import React, { Component } from "react";
import FooterV1 from "../library/FooterV1";

class Thankyouforregister extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <section className="contact_page">
                    <div className="container ">
                        <div className="row">
                            <div className="col-12 mt-sm-5 mt-4">
                                <div className="row col-12 justify-content-center">
                                    <div className="col-9">
                                        <h2>Inscrivez vous</h2>
                                        <br/>
                                    </div>
                                    <div id="contact-message" className="col-lg-12 col-sm-12">&nbsp;</div>
                                    <div className="col-9" style={{marginBottom: '20px'}}>
                                        Merci de vous inscrire auprès de Sybe-Solutions<br/>
                                        Vous recevrez sous peu un courriel pour vérifier votre adresse électronique.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterV1/>
                </section>
            </>
        );
    }
}

export default Thankyouforregister;
