export function formaudit(form) {

    const validationRules = {

        email: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        string: (value) => value.trim().length > 0,
        password: (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/.test(value),
        select: (value) => Array.isArray(value) ? value.length > 0 : !!value,
        passpass: (value, ref) => value === form.form[ref].value,
        check: (value) => !!value,
        multiselect: (value) => Array.isArray(value) && value.length > 0,
        int: (value) => parseInt(value) > 0,
        url: (value) => {
            const urlPattern = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(\/.*)?$/;
            return urlPattern.test(value);
        },
        date: (value) => /^\d{4}-\d{2}-\d{2}$/.test(value.trim()),
        file: (value) => value instanceof File

    };

    let isFormValid = true;
    let errorMessage = '';
    const formData = {};

    for (let [key, { type, value, required, err_message, ref }] of Object.entries(form.form)) {
        if (required) {
            const validator = validationRules[type];
            if (!validator || !validator(value, ref)) {
                isFormValid = false;
                errorMessage = err_message;
                break;
            }
            formData[key] = type === 'int' ? parseInt(value) : value;
        } else {
            formData[key] = value;
        }
    }

    if (isFormValid) {
        return {
            success: true,
            message: form.formsuccess_message,
            post: formData,
        };
    } else {
        return {
            success: false,
            message: errorMessage,
        };
    }
}