import React from "react";
import FooterV1 from "../library/FooterV1";
const Propos = () => {
    return (
        <>
            <section className={`featured-section bgs-cover rpt-150 pb-120 rpb-100`}
                     >
                <div className="container">
                    <div className="section-title mb-55">
                        <h2 style={{ marginBottom: '50px' , marginTop: '50px' }}>Mentions Légales</h2>
                        <p style={{fontSize: '22px', textAlign: 'left'}}>
                            Le site www.sybe-solutions.com (le “Site”) est opéré par la société SYBE Solutions SAS (la
                            “Société” ou
                            “Nous”), une société par actions simplifiées au capital de 5000 € dont le
                            siège est situé à l’adresse suivante : 39 route de Naz Dessus, Villa 7, 01170 Échenevex
                            et dont les bureaux sont situés à l’adresse suivante : 50 Rue Gustave Eiffel
                            01630 Saint-Genis-Pouilly, France, enregistrée avec le SIREN n°985367812. <br/><br/>
                            Le numéro de TVA intracommunautaire de la société est le suivant : FR27985367812.<br/><br/>
                            Le Site est hébergé chez Hostpoint.ch, Hostpoint AG, Neue Jonastrasse 60, 8640
                            Rapperswil-Jona, Suisse,
                            +41 55 220 6312 . Pour toute autre question, Nous vous invitons à prendre contact via
                            l’adresse
                            courriel suivante : info@sybe-solutions.com.
                        </p>
                    </div>

                </div>
            </section>
            <FooterV1/>
        </>
    );
}

export default Propos;