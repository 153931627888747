import React from "react";
import GalleryV2Data from "../library/GalleryV2Data.json";
import SingleGalleryV2 from "../library/SingleGalleryV2";
import FooterV1 from "../library/FooterV1";
import { Link } from "react-router-dom";
const Solutions = () => {
  return (
    <>
      <section className="gallery-section-two overlay pt-115 rpt-95 pb-85 rpb-65 px-60 rpx-0">
        <div className="container">
          <div className="section-title text-center mb-55">
            <span className="sub-title">Nos solutions</span>
            <h2>
              Avec Sybe solutions auditez et protégez votre forteresse numérique
            </h2>
          </div>
          <div className="tw-gap-4 tw-grid md:tw-grid-cols-2  ">
            <>
              {GalleryV2Data.map((gallery, index) => (
                <div
                  className={
                    index >= GalleryV2Data.length - 1 ? "md:tw-col-span-2" : ""
                  }
                  key={gallery.id}
                >
                  <SingleGalleryV2 gallery={gallery} />
                </div>
              ))}
            </>
          </div>
        </div>
      </section>
      <section className="tw-py-16 tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center">
        <div className="tw-container tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-items-center tw-w-full">
          <h3 className="tw-text-2xl">
            Des questions concernant nos solutions ? Contactez-nous !
          </h3>
          <div className="hero-btn fade-in-up">
            <Link to="/contact" className="theme-btn">
              Nous contacter
            </Link>
          </div>
        </div>
      </section>
      <FooterV1 />
    </>
  );
};
export default Solutions;
