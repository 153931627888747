import React, { useState, useEffect } from "react";
import FooterV1 from "../library/FooterV1";
import { Link, useParams } from "react-router-dom";
import { MoveUpRight } from "lucide-react";
import BlogArticles from "../library/BlogArticles.json";
import Accordion from "../library/accordion";
import accordionData from "../library/accordionDataArticleOne";
// TODO : Icons pour les AUDITS, autres pages = autre icons a trouver ( aubin)
const Blogdetails = () => {
  const { id } = useParams();
  const [article, setProduct] = useState(null);

  useEffect(() => {
    const selectedProduct = BlogArticles.find(
      (article) => article.id === parseInt(id)
    );
    if (selectedProduct) {
      setProduct(selectedProduct);
    }
  }, [id]);
  const openPdfNIS2 = () => {
    window.open("../assets/CGU-22042024.pdf", "_blank");
  };
  const allArticles = BlogArticles;
  return (
    <>
      {article && (
        <section className="portfolio-details pt-120 rpt-100 pb-90 rpb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="portfolio-details-content rmb-55">
                  <h1>{article.title}</h1>
                  <div className="image mb-35">
                    <img
                      className="tw-rounded-xl"
                      src={`/assets/images/services/${article.thumb}`}
                      alt="Article Details"
                    />
                  </div>
                  <article>
                    <p>
                      Ce n’est un secret pour personne : les cyberattaques se
                      multiplient. Avec la transformation numérique et
                      l’interconnexion des pays européens, le marché numérique
                      devient plus vulnérable aux cybermenaces.
                      <br/><br/>
                      Pour répondre à
                      ce défi, l'Union européenne a introduit la{" "}
                      <a
                        className="tw-text-main-blue"
                        href="https://cyber.gouv.fr/la-directive-nis-2"
                        target="_blank"
                      >
                        directive NIS2 (Network and Information Security 2)
                      </a>
                      . Cette directive vise à renforcer la sécurité des
                      infrastructures critiques - comme les réseaux d'énergie et
                      les services de santé - et des services essentiels - comme
                      les banques et les transports - en Europe. <br/><br/>Mais comment
                      les entreprises peuvent-elles s’y conformer efficacement ?
                      Qui est concerné par la mise en conformité prévue par la
                      directive NIS2 ? Quelle feuille de route mettre en place ?
                      Dans cet article nous avons fait un condensé de tout ce
                      que vous devez savoir sur cette nouvelle réglementation en
                      cybersécurité. Découvrez comment mettre en place les
                      mesures nécessaires pour sécuriser vos systèmes
                      d’information face aux menaces croissantes.
                    </p>
                    <h2>
                      Qui est concerné par la cybersécurité et la réglementation
                      NIS ?
                    </h2>
                    <p>
                      La{" "}
                      <a
                        className="tw-text-main-blue"
                        href="https://cyber.gouv.fr/la-directive-nis-2"
                        target="_blank"
                      >
                        cybersécurité
                      </a>{" "}
                      touche toutes les entreprises privées ou publiques, sans
                      exception. Que vous soyez une petite entreprise familiale,
                      une mairie, un médecin, une start-up innovante ou une
                      multinationale. Les hackers ne font pas de discrimination.<br/><br/>
                      Les motivations des cybercriminels sont variées : vol de
                      données, demandes de rançon, sabotage, ou encore
                      espionnage industriel. Peu importe la taille ou le secteur
                      d'activité de votre entreprise, la vigilance est de mise.<br/><br/>
                      En 2016, le Parlement et le Conseil de l’Union européenne
                      ont adopté les premières mesures de cybersécurité pour le
                      marché européen, regroupées sous le nom de NIS (Network
                      and Information Security). Elle visait à renforcer la
                      cybersécurité des infrastructures essentielles en imposant
                      des obligations de déclaration des incidents et mesures de
                      protection. <br/><br/>La lutte contre la cybercriminalité devient
                      enfin un travail d’équipe ! Cependant, face à l'évolution
                      rapide des menaces cybernétiques et à la sophistication
                      croissante des attaques. Il est vite apparu que la
                      directive NIS nécessitait une mise à jour. Les
                      cybercriminels devenant de plus en plus performants et
                      mieux équipés. Il était crucial d'élargir le champ
                      d'application et de renforcer les mesures de sécurité.
                      C’est dans ce contexte que la directive NIS2 a été
                      introduite. Cette nouvelle version élargit ses objectifs
                      et son périmètre pour inclure un plus grand nombre de
                      secteurs et d’entités, apportant ainsi une protection
                      accrue contre les dangers numériques.
                    </p>
                    <h3>Les secteurs essentiels concernés par la NIS</h3>
                    <div>
                      La directive NIS2 identifie plusieurs secteurs considérés
                      comme essentiels. Ces secteurs jouent un rôle crucial dans
                      notre vie quotidienne et le bon fonctionnement de
                      l'économie.
                      <p></p>
                      Parmi eux, on trouve :<p></p>
                      <ul className="tw-pl-4 !tw-list-disc !tw-list-inside tw-mb-10 tw-flex tw-flex-col tw-gap-4">
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>L'énergie</strong> : Ce secteur inclut les
                          fournisseurs d'électricité, de gaz, d’hydrogène, de
                          pétrole et les réseaux de chaleur et de froid.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>La santé</strong> : Les hôpitaux, cliniques et
                          autres services de santé sont inclus.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>Les transports</strong> : Cela concerne les
                          compagnies aériennes, ferroviaires, maritimes et de
                          transport routier.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>
                            Les banques et les institutions financières
                          </strong>{" "}
                          : Les banques, assureurs et autres services financiers
                          sont couverts par la directive.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>
                            Les fournisseurs de services numériques
                          </strong>{" "}
                          : Cela inclut les hébergeurs de données, les
                          plateformes de commerce électronique et les moteurs de
                          recherche…
                        </li>
                      </ul>
                    </div>
                    <p></p>
                    <h3>Les secteurs importants concernés par la NIS</h3>
                    <div>
                      En plus des secteurs essentiels, cette nouvelle
                      réglementation s'applique également à des secteurs jugés
                      importants pour la société et l'économie.
                      <p></p>
                      Ces secteurs incluent notamment :<p></p>
                      <ul className="tw-pl-4 !tw-list-disc tw-list-inside tw-mb-10 tw-flex tw-flex-col tw-gap-4">
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>La gestion des déchets</strong> : Cela
                          concerne les entreprises de collecte, de traitement et
                          d'élimination des déchets.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>La recherche et le développement</strong> :
                          Les instituts de recherche, laboratoires et centres de
                          développement technologique sont inclus.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>Les services de distribution d'eau</strong> :
                          Les fournisseurs et distributeurs d'eau potable sont
                          également concernés.
                        </li>
                        <li className="!tw-list-disc !tw-list-inside">
                          <strong>
                            Les infrastructures de télécommunications
                          </strong>{" "}
                          : Les fournisseurs de services de télécommunications
                          et d'internet sont couverts par la directive…
                        </li>
                      </ul>
                      <div className="hero-btn fade-in-up tw-my-10">
                        <Link className="theme-btn" onClick={openPdfNIS2}>
                          Télécharger les annexes de la directive NIS 2 [PDF]
                        </Link>
                      </div>
                    </div>
                    <h3>
                      Pourquoi ces entreprises doivent se conformer à cette
                      réglementation ?
                    </h3>
                    <p>
                      La conformité à la directive NIS2 est cruciale pour
                      plusieurs raisons :
                    </p>
                    <p>
                      <b>Protection contre les cyberattaques</b>
                      <br />
                      Les entreprises des secteurs essentiels et importants sont
                      des cibles privilégiées pour les pirates informatiques. Un
                      piratage réussi contre l'une de ces entités peut avoir des
                      conséquences graves pour l'économie et la sécurité
                      nationale.
                      <br />
                      <br />
                      <b>Maintien de la confiance</b>
                      <br />
                      En se conformant à la directive NIS2, les entreprises
                      montrent qu'elles prennent la cybersécurité au sérieux.
                      Renforçant ainsi la confiance de leurs clients et
                      partenaires.
                      <br />
                      <br />
                      <b>Prévention des perturbations</b>
                      <br />
                      La mise en place de mesures de cyberdéfense robustes
                      réduit les vulnérabilités et les risques de perturbations
                      opérationnelles causées par des cyberattaques.
                      <br />
                      <br />
                      <b>Respect des obligations légales</b>
                      <br />
                      La non-conformité au-delà des risques numériques peut
                      entraîner des sanctions sévères, y compris des amendes et
                      des actions en justice.
                      <br />
                      <br />
                      La directive NIS2 impose des obligations de lutte contre
                      la cybercriminalité strictes aux entreprises opérant dans
                      des secteurs critiques et importants. Ces mesures visent à
                      protéger les infrastructures essentielles et à garantir la
                      continuité des services vitaux pour la société.
                    </p>
                    <h2>Comprendre la directive NIS 2 </h2>
                    <p>
                      Comme nous l'avons vu, la directive NIS (Network and
                      Information Security) a été adoptée en 2016 pour renforcer
                      la cybersécurité des infrastructures critiques en Europe.
                      Elle impose des obligations aux États membres et aux
                      opérateurs de services essentiels. Cela afin de protéger
                      les réseaux et systèmes d'information contre les
                      cybermenaces.
                    </p>
                    <h3>Pourquoi la directive NIS est-elle importante ?</h3>
                    <p>La directive NIS est indispensable car elle :</p>
                    <ul className="tw-pl-4 !tw-list-disc tw-list-inside tw-mb-10 tw-flex tw-flex-col tw-gap-4">
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>
                          Assure la protection des services vitaux:
                        </strong>
                        <br /> En sécurisant les systèmes d'information des
                        secteurs clés comme l'énergie, la santé et les
                        transports.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Réduit les risques de cyberattaques:</strong>
                        <br /> En imposant des mesures de sécurité robustes pour
                        prévenir et gérer les incidents.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Uniformise les normes de sécurité:</strong>
                        <br /> En harmonisant les pratiques de cybersécurité à
                        travers l'Union européenne.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Favorise la coopération internationale:</strong>
                        <br /> En encourageant les échanges d'informations et la
                        collaboration entre les États membres.
                      </li>
                    </ul>
                    <br />
                    <h3>
                      Quelles sont les exigences clés de la directive NIS 2 ?
                    </h3>
                    <p>
                      La directive NIS 2, en élargissant les objectifs de la NIS
                      initiale, introduit plusieurs exigences pour améliorer la
                      cybersécurité.
                      <br />
                      Voici un aperçu des principales obligations :
                    </p>
                    <table class="table-auto w-full tw-mb-10">
                      <thead>
                        <tr class="bg-gray-200">
                          <th class="px-4 py-2">Exigence</th>
                          <th class="px-4 py-2">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border px-4 py-2">
                            Sécurisation des réseaux et systèmes
                          </td>
                          <td class="border px-4 py-2">
                            Adopter des mesures techniques et organisationnelles
                            pour protéger les réseaux et systèmes contre les
                            cyberrisques. Cela inclut l'utilisation de pare-feu,
                            de systèmes de détection d'intrusion, de chiffrement
                            des données et de politiques de gestion des accès.
                          </td>
                        </tr>
                        <tr>
                          <td class="border px-4 py-2">
                            Signalement des incidents
                          </td>
                          <td class="border px-4 py-2">
                            Informer rapidement les autorités compétentes en cas
                            d'incident de sécurité majeur. Cela permet une
                            réponse rapide et coordonnée pour minimiser les
                            impacts de l'attaque informatique.
                          </td>
                        </tr>
                        <tr>
                          <td class="border px-4 py-2">
                            Prévention et gestion des crises
                          </td>
                          <td class="border px-4 py-2">
                            Élaborer des plans pour prévenir et gérer les
                            incidents de cybersécurité. Ces plans doivent
                            inclure des procédures pour identifier les menaces,
                            évaluer les risques et mettre en œuvre des mesures
                            correctives.
                          </td>
                        </tr>
                        <tr>
                          <td class="border px-4 py-2">Collaboration</td>
                          <td class="border px-4 py-2">
                            Coopérer avec les autorités et partager des
                            informations sur les cybermenaces. Les entreprises
                            doivent partager des informations sur les menaces et
                            les incidents, ainsi que les meilleures pratiques en
                            matière de cybersécurité.
                          </td>
                        </tr>
                        <tr>
                          <td class="border px-4 py-2">
                            Formation et sensibilisation
                          </td>
                          <td class="border px-4 py-2">
                            Former les employés aux risques de cybersécurité et
                            aux bonnes pratiques. Des programmes de formation
                            réguliers et des campagnes de sensibilisation
                            peuvent aider à instaurer une culture de sécurité au
                            sein de l'organisation.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>
                      Ces exigences rigoureuses permettent de renforcer la
                      sécurisation des réseaux et des systèmes d’information en
                      Europe. Elles donnent également la possibilité aux
                      entreprises de mieux se protéger contre les cybercrimes.
                    </p>
                    <h2>
                      Pourquoi la cybersécurité est cruciale pour les
                      entreprises ?
                    </h2>
                    <p>
                      Imaginez une entreprise prospère, qui fonctionne sans
                      encombre et enregistre une croissance régulière. Un jour,
                      une attaque informatique paralyse ses systèmes, entraînant
                      des pertes financières et ternissant sa réputation. C'est
                      une situation que personne ne souhaite vivre, mais qui
                      devient de plus en plus courante. Comprendre pourquoi la
                      cybersécurité est essentielle permet d'éviter de tels
                      scénarios.
                    </p>
                    <h3>
                      4 types de menaces cybernétiques courantes à connaître
                    </h3>
                    <p>
                      Les cybercriminels utilisent diverses méthodes pour
                      attaquer les entreprises. Voici quelques menaces courantes
                      à connaître :
                    </p>
                    <ul className="tw-pl-4 !tw-list-disc tw-list-inside tw-mb-10 tw-flex tw-flex-col tw-gap-4">
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Phishing:</strong> Les hackers envoient des
                        emails frauduleux pour tromper les employés et obtenir
                        des informations sensibles comme des mots de passe ou
                        des données financières.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Ransomware:</strong> Ce type de logiciel
                        malveillant crypte les données de l'entreprise, exigeant
                        une rançon pour les débloquer. Cela peut paralyser les
                        opérations pendant des jours, voire des semaines.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Malware:</strong> Les logiciels malveillants
                        peuvent infecter les systèmes informatiques et voler des
                        informations, espionner les activités ou causer des
                        dommages.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        <strong>Attaques par déni de service (DDoS):</strong>{" "}
                        Ces attaques surchargent les serveurs de l'entreprise,
                        rendant les services indisponibles pour les utilisateurs
                        légitimes.
                      </li>
                    </ul>
                    <h3>
                      Conséquences dévastatrices des cyberattaques pour votre
                      entreprise
                    </h3>
                    <p>
                      Les cyberattaques ont des impacts multiples et sévères.
                      Financièrement, les coûts peuvent être énormes. Payer une
                      rançon, restaurer les systèmes, compenser les pertes de
                      revenus, tout cela coûte cher. Sur le plan de la
                      réputation, une attaque peut éroder la confiance des
                      clients et des partenaires. La non-conformité avec les
                      réglementations de protection des données peut entraîner
                      des amendes. La perte de données sensibles compromet la
                      sécurité de vos clients et employés.
                    </p>
                    <h3>
                      Quelles sont les actions d’urgence pour protéger votre
                      entreprise ?
                    </h3>
                    <p>
                      Face à ce type de menaces, il est crucial de savoir
                      quelles actions entreprendre immédiatement pour protéger
                      votre entreprise :
                    </p>
                    <ul className="tw-pl-4 !tw-list-disc tw-list-inside tw-mb-10 tw-flex tw-flex-col tw-gap-4">
                      <li className="!tw-list-disc !tw-list-inside">
                        Sensibilisez vos employés aux risques de piratage et aux
                        bonnes pratiques en matière de sécurité informatique.
                        Des employés bien informés sont la première ligne de
                        défense contre les attaques.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        Assurez-vous que tous vos logiciels et systèmes sont à
                        jour avec les derniers correctifs de sécurité. Les
                        vulnérabilités non corrigées sont des portes ouvertes
                        pour les cybercriminels.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        Effectuez des sauvegardes régulières de vos données
                        critiques. En cas d'attaque, cela vous permettra de
                        restaurer rapidement vos systèmes sans payer de rançon.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        Installez des antivirus, des pare-feu et des systèmes de
                        détection des intrusions pour protéger vos réseaux et
                        vos systèmes.
                      </li>
                      <li className="!tw-list-disc !tw-list-inside">
                        Développez et testez régulièrement un plan de réponse
                        aux incidents de cybersécurité. Cela inclut des
                        procédures claires pour détecter, évaluer et répondre
                        aux cyberattaques.
                      </li>
                    </ul>

                    <p>
                      En mettant en place ces mesures, vous pouvez renforcer la
                      résilience de votre entreprise face aux piratages
                      informatiques et minimiser les impacts d'éventuelles
                      attaques. La cybersécurité n'est pas une option, mais une
                      nécessité pour assurer la continuité et la pérennité de
                      votre activité.
                    </p>
                    <h2>Comment Sybe Solutions vous accompagne ? </h2>
                    <p>
                      Notre approche est holistique et personnalisée. Nous
                      comprenons que chaque entreprise est unique. Chaque
                      organisation nécessite des solutions spécifiques. De
                      l'évaluation de vos vulnérabilités à la formation de votre
                      personnel, nous vous accompagnons à chaque étape.
                      Découvrez comment nous renforçons la sécurité de vos
                      systèmes d'information. Assurons ensemble la pérennité de
                      votre activité.
                    </p>
                    <h3>Audits de sécurité informatique de votre entreprise</h3>
                    <p>
                      Pour une cybersécurité robuste, il est essentiel de
                      comprendre les failles potentielles. C’est pourquoi nous
                      avons créé des audits de sécurité approfondis. Nos experts
                      en sécurité examinent l'ensemble de vos infrastructures
                      informatiques. Ils identifient les vulnérabilités et
                      proposent des solutions spécifiques. Ces audits
                      fournissent une analyse des risques claire et détaillée.
                      Vous êtes guidé pas à pas pour améliorer la sécurisation
                      de votre infrastructure..
                    </p>
                    <h3>
                      Programmes de formation et sensibilisation à la
                      cybersécurité
                    </h3>
                    <p>
                      La formation et la sensibilisation des employés sont
                      essentielles pour prévenir les cyberattaques. Nous
                      proposons des programmes de formation variés. Ces
                      formations sont adaptées à tous les niveaux de
                      compétences. Elles couvrent les menaces actuelles et les
                      bonnes pratiques de sécurité numérique. En éduquant votre
                      personnel, vous renforcez la première ligne de défense
                      contre les attaques digitales.
                    </p>
                    <h3>
                      Services de consulting personnalisés en cybersécurité
                    </h3>
                    <p>
                      Chaque entreprise a des besoins spécifiques en
                      cybersécurité. Nous offrons des services de consulting
                      sur-mesure. Nos consultants sécurité collaborent avec vous
                      pour élaborer des stratégies de sécurité adaptées et
                      prévenir les attaques. Ils aident à mettre en place des
                      plans de réponse aux incidents. Leur solutions de
                      sécurisation permet de protéger vos systèmes d’information
                      de manière optimale.
                    </p>
                    <h3>
                      Veille réglementaire pour rester conforme aux évolutions
                    </h3>
                    <p>
                      Les réglementations en cybersécurité évoluent constamment.
                      Il est crucial de rester informé pour éviter les
                      sanctions. Nous assurons une veille réglementaire
                      continue. Nous vous tenons informé des changements
                      législatifs pertinents. Tout en vous conseillant sur les
                      ajustements nécessaires pour rester conforme. La garantie
                      d'une gestion sereine de vos obligations.
                    </p>
                    <h2>
                      Pourquoi choisir Sybe Solutions pour vos besoins en
                      cybersécurité ?
                    </h2>
                    <p>
                      Choisir le bon partenaire en cybersécurité est crucial
                      pour protéger votre entreprise et lutter contre les
                      intrusions informatiques. Voici pourquoi Sybe Solutions
                      est votre meilleur choix.
                    </p>
                    <ul className="tw-pl-4 !tw-list-disc tw-list-inside tw-mb-10 tw-flex tw-flex-col tw-gap-4">
                      <li>
                        ✅{" "}
                        <strong>
                          Vous cherchez des solutions de cybersécurité simples à
                          mettre en place.
                        </strong>{" "}
                        Nos services sont conçus pour être intuitifs et ne
                        nécessitent pas de ressources techniques importantes.
                        Vous pouvez ainsi vous concentrer sur votre cœur de
                        métier, tout en bénéficiant d'une Cyber-protection
                        efficace.
                      </li>
                      <li>
                        ✅{" "}
                        <strong>
                          Il est essentiel que vos systèmes d’informations
                          soient protégés de manière rapide et sans complexité.
                        </strong>{" "}
                        Nos solutions de sécurité informatique sont simples à
                        mettre en œuvre, que vous dirigiez une petite entreprise
                        ou une grande organisation. Cette simplicité garantit
                        une protection rapide et efficace de vos données.
                      </li>
                      <li>
                        ✅{" "}
                        <strong>
                          En tant que petite ou moyenne entreprise, vous avez
                          des besoins particuliers.
                        </strong>{" "}
                        Nos politiques de sécurité sont spécialement conçues
                        pour les TPE et PME, offrant des solutions adaptées à
                        votre budget et à vos exigences.
                      </li>
                      <li>
                        ✅{" "}
                        <strong>
                          Vous recherchez un support continu et un
                          accompagnement personnalisé.
                        </strong>{" "}
                        Nos spécialistes en sécurité sont toujours disponibles
                        pour répondre à vos questions et vous assister en cas
                        d'incident. Vous bénéficiez ainsi d'un soutien constant
                        pour maintenir un haut niveau de sécurité.
                      </li>
                      <li>
                        ✅{" "}
                        <strong>
                          Vous voulez travailler avec des experts en
                          cybersécurité.
                        </strong>{" "}
                        Notre équipe possède les compétences nécessaires pour
                        protéger vos systèmes contre les menaces les plus
                        sophistiquées. Vous pouvez compter sur notre fiabilité
                        et notre sérieux pour éviter les attaques et posséder
                        une politique de sécurisation solide.
                      </li>
                      <li>
                        ✅{" "}
                        <strong>
                          Vous êtes conscient que les cybermenaces évoluent sans
                          cesse.
                        </strong>{" "}
                        Nous adoptons une approche proactive et adaptable,
                        surveillant en permanence les évolutions du paysage
                        cybernétique. Vous restez ainsi protégé contre les
                        nouvelles menaces.
                      </li>
                    </ul>
                    <p>
                      Nous adoptons une approche proactive et adaptable,
                      surveillant en permanence les évolutions du paysage
                      cybernétique. Vous restez ainsi protégé contre les
                      nouvelles menaces. Votre entreprise est unique et nous le
                      savons ! C’est pourquoi nous avons créé des solutions sur
                      mesure pour s’aligner avec vos objectifs, vos contraintes
                      et la réglementation. Vous assurant une protection adaptée
                      à votre environnement et votre marché. En choisissant Sybe
                      Solutions, vous optez pour un partenaire qui rend la
                      cybersécurité accessible et efficace. Notre simplicité de
                      mise en œuvre, notre assistance continue et notre
                      expertise reconnue garantissent la sécurité et la
                      pérennité de vos activités. Faites confiance à notre
                      savoir-faire pour protéger votre entreprise.
                    </p>

                    <h2>
                      Assurez l'avenir de votre entreprise avec une
                      cybersécurité conforme
                    </h2>
                    <p>
                      La cybersécurité est un pilier essentiel pour la
                      continuité et la pérennité de votre entreprise dans un
                      monde numérique en constante évolution. La directive NIS2
                      impose des mesures strictes pour protéger les
                      infrastructures critiques et les services essentiels en
                      Europe. Comprendre ces exigences et les appliquer
                      correctement est crucial pour éviter les cybermenaces.
                      C’est pourquoi nous avons choisi de vous accompagner avec
                      des services personnalisés, des formations adaptées et une
                      veille réglementaire continue pour garantir la sécurité
                      des systèmes d’information. Ne laissez pas les
                      cybermenaces compromettre vos activités.
                    </p>
                    <p>
                      {" "}
                      <a className="tw-text-main-blue" href="/contact">
                        Contactez dès maintenant un expert en cybersécurité
                      </a>{" "}
                      chez Sybe Solutions et sécurisez votre entreprise pour
                      l'avenir.
                    </p>
                  </article>
                  <div>
                    <h2>FAQ</h2>
                    <Accordion accordionData={accordionData} />
                  </div>
                  <div className="row mt-45">
                    <section className="tw-p-20 tw-w-full tw-mx-3 tw-bg-light-blue tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-xl">
                      <div className="tw-container tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-items-center tw-w-full">
                        <h3 className="tw-text-2xl">
                          Des questions concernant nos solutions ?
                        </h3>
                        <div className="tw-flex tw-flex-row tw-gap-10">
                          <div className="hero-btn fade-in-up">
                            <Link to="/contact" className="theme-btn">
                              Nous contacter
                            </Link>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="portfolio-sidebar tw-mt-14">
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    {allArticles.map((service) => (
                      <div
                        key={service.id}
                        className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-bg-light-blue tw-p-3 tw-rounded tw-mb-3"
                      >
                        <Link
                          to={`/blogdetails/${service.id}`}
                          className="tw-w-full tw-flex tw-justify-between"
                        >
                          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                            <span className="tw-text-xl tw-font-bold">
                              {service.title}
                            </span>
                          </div>

                          <MoveUpRight />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      ;
      <FooterV1 />
    </>
  );
};

export default Blogdetails;
