module.exports = global.insertuserform = {
    form:{

        ucompany:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm.... please enter a company name',
            }
        },
        utitle:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a title',
            }
        },
        ufirstname:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a valid first name'
            }
        },
        ulastname:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a valid last name'
            }
        },
        uphone:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a phone number',
            }
        },
        uemail:{
            'value':'',
            'required':true,
            'type':'string',
            err_message:{
                en:'Hmmm... Please enter a valid email'
            }
        },
        upass:{
            'value':'',
            'required':true,
            'type':'password',
            err_message:{
                en:'Hmmm... Please enter a valid password'
            }
        },
        upass2:{
            'value':'',
            'required':false,
            'type':'password',
            err_message:{
                en:'Hmmm... Please enter a valid password'
            }
        },
    },
    formsuccess_message: {
        en: 'Communicating with server'
    },
    server_error_message: {
        en: 'Username and password do not match',
    }
}