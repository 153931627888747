import React, {useEffect} from "react";
import FooterV1 from "../library/FooterV1";
import BlogSingle from "../library/BlogSingle";
import BlogArticles from "../library/BlogArticles.json";
const Blog = () => {

        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        return (
            <>
            <section className="gallery-section-two overlay pt-40 rpt-95 pb-40 rpb-65 px-60 rpx-0 tw-mb-10">
                <div className="container-fluid">
                  <div className="section-title text-center">
                    <h2>Blog</h2>
                  </div>
                   
                </div>
                
            </section>
            <section className="container-fluid">
                <div className="tw-container tw-mx-auto tw-mb-10">
                    <div className="tw-gap-4 tw-grid md:tw-grid-cols-2  ">
                    <>
                        {BlogArticles.map((gallery, index) => (
                            <div key={gallery.id}
                            >
                            <BlogSingle gallery={gallery} />
                            </div>
                        ))}
                    </>
                    </div>
                </div>
            </section>
        
              <FooterV1 />
            </>
          );
}

export default Blog;