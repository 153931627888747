import React from "react";
import { Link } from "react-router-dom";
import { Linkedin } from "lucide-react";
const SocialShare = ({ linkedinUrl }) => {

  return (
    <>
      <Link to={linkedinUrl} target="_blank">
        <Linkedin />
      </Link>
    </>
  );
};

export default SocialShare;
