import React, {useEffect} from "react";
import FooterV1 from "../library/FooterV1";
const Legal = () => {

        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        return (
        <>
            <section className="blog-details-area bg-lighter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-14">
                            <div className="blog-details-content pt-120 rpt-100 pb-95 rpb-75 pr-15 rpr-0">
                                <div className="blog-header">
                                    <ul className="post-meta-item mr-15">
                                        <li>
                                            <i className="fas fa-calendar-alt"></i>
                                            <p>25 Avril 2024</p>
                                        </li>
                                    </ul>

                                </div>
                                <h3>Les Conditions Générales d’Utilisation du Site</h3>
                                <blockquote>Pour rappel</blockquote>
                                <p>www.sybe-solutions.com (le “Site”) est opéré par Sybe Solutions SAS (la “Société”) et
                                    est hebergé Hostpoint.ch, Hostpoint AG, Neue Jonastrasse 60, 8640 Rapperswil-Jona,
                                    Suisse (“Hébergeur”).</p>
                                <blockquote>Important</blockquote>
                                <p>Les informations contenues dans le Site ne constituent en aucun cas une forme de
                                    conseil et sont diffusées à titre informatif uniquement.</p>
                                <blockquote>La nature du document</blockquote>
                                <p>Les Conditions d’Utilisation du Site sont les règles à respecter lorsque l’on navigue
                                    sur le Site et lorsque que l’on utilise les fonctionnalité du Site et auxquelles les
                                    Visiteurs ou les Utilisateurs acceptent de se conformer.</p>
                                <p>Elles forment un tout avec la Politique de Confidentialité, la Politique de Cookie et
                                    les Mentions Légales du Site. Les termes qui revêtent une majuscule et qui ne sont
                                    pas définis dans le présent document sont définis dans un des documents ci dessus
                                    mentionnés.</p>
                                <blockquote>Le but du Site</blockquote>
                                <p>Le Site vise à présenter les services de la Société (les “Services”) et permet au
                                    Visiteur ou à l’Utilisateur de découvrir le projet, les Services, l’équipe du
                                    projet, d’obtenir les informations pour contacter la Société et obtenir un accès à
                                    la WebApp.</p>
                                <p>
                                    Si un Visiteur ou un Utilisateur utilise le Site, il reconnait avoir pris
                                    connaissance, avoir lu et avoir compris les Conditions Générales d’Utilisation du
                                    Site. Si ce n’est pas le cas, il est recommandé de ne pas naviguer ni d’utiliser les
                                    fonctionnalités proposées sur le Site.</p>
                                <p>Les Conditions Générales d’Utilisation du Site sont acceptés dès lors que le Visiteur
                                    ou l’Utilisateur coche et clique sur sur “Accepter” pour valider son choix lorsque
                                    la pop up
                                    box suivante apparait : <span style={{fontStyle: 'italic'}}>Avant de continuer, veuillez prendre un moment pour consulter notre Politique de Confidentialité et nos Conditions Générales d’Utilisation du Site. En utilisant ce site web, vous acceptez les termes décrits dans notre Politique de Confidentialité et nos Conditions Générales d’Utilisation du Site. Votre vie privée et la sécurité de vos données sont importantes pour nous. Cochez et cliquez sur "Accepter" pour continuer ou sur "En savoir plus" pour consulter notre Politique de Confidentialité et nos Conditions Générales d’Utilisation du Site.</span>
                                </p>
                                <p>Le Visiteur ou l’Utilisateur qui utilise le Site pour le compte d’une personne morale
                                    (par exemple d’une société) doit s’assurer d’avoir l’autorité nécessaire pour le
                                    faire.</p>
                                <blockquote>La modification du document</blockquote>
                                <p>La Société est libre d’apporter des modifications à ce document. Si tel est le cas,
                                    les Visiteurs et les Utilisateurs seront notifiés par email et/ou une pop-up box qui
                                    visera à recueillir leur consentement pour les modifications réalisées.</p>
                                <p>Le Visiteur ou l’Utilisateur devront cocher “J’accepte les modifications et la
                                    nouvelle version des Conditions Générales d’Utilisation du Site” et valider leur
                                    choix en cliquant sur “Poursuivre”. Si les modifications, sont refusées alors il est
                                    recommandé de ne pas utiliser le Site.</p>
                                <blockquote>Le site</blockquote>
                                <p>Le Site est mis à disposition en l’état, communément dit “on as is basis” et son
                                    utilisation n’a pas de garanties spécifiques dans la limite maximale permise par le
                                    droit.</p>
                                <blockquote>L’utilisation du Site</blockquote>
                                <p>La Société octroie à ses Visiteurs et Utilisateurs une licence limitée, non
                                    exclusive, non transférable, et ne pouvant pas faire l’objet d’une sous licence sur
                                    le Site. La licence est révocable. Ce n’est pas une licence commerciale.</p>
                                <p>Toutes les autres utilisations qui sont faites doivent recueillir l’accord écrit
                                    préalable de la Société.</p>
                                <blockquote>L’utilisation des fonctionnalités du Site</blockquote>
                                <p>La Société propose des fonctionnalités, notamment l’accès à une WebApp. L’accès à la
                                    WebApp fait l’objet d’une licence limitée, non exclusive, non transférable, et ne
                                    pouvant pas faire l’objet d’une sous licence. La licence est révocable. Ce sont les
                                    Conditions Générale d’Utilisation de la WebApp. Ce n’est pas une licence
                                    commerciale.</p>
                                <p>Toutes les autres utilisations qui sont faites doivent recueillir l’accord écrit
                                    préalable de la Société.</p>
                                <blockquote>L’Espace Utilisateur sur le Site</blockquote>
                                <p>Si un Visiteur le souhaite, il peut créer un espace utilisateur (“Espace
                                    Utilisateur”) pour bénéficier des fonctionnalités mises à disposition sur le
                                    Site.</p>
                                <p>Au moment de la création de l’Espace Utilisateur, des Données Personnelles peuvent
                                    être recueillies conformément à la Politique de Confidentialité et il faudra
                                    accepter les Conditions Générales d’Utilisation de la WebApp.</p>
                                <blockquote>Les actions interdites</blockquote>
                                <p>Sont interdites les utilisations frauduleuses ou illégales conduites sur le Site
                                    ainsi que toutes utilisations qui contreviennent aux Politiques de Confidentialité,
                                    de Cookie, ou aux Condititions Générales d’Utilisation du Site ou de la WebApp.</p>
                                <blockquote>Ce que fait et ne fait pas la Société</blockquote>
                                <p>La Société opère le Site, propose ses Services et donne accès à une WebApp.</p>
                                <p>La Société ne donne pas de conseils ou recommandations juridiques, financière, ou
                                    comptables personnalisées dans le contenu de son Site.</p>
                                <p>Le Visiteur et l’Utilisateur sont seuls responsables des décisions stratégiques qu’il
                                    prend en matière de cybersécurité.</p>
                                <blockquote>La confidentialité</blockquote>
                                <p>La Société peut Traiter des Données Personnelles. Le Visiteur et l’Utilisateur
                                    acceptent que la Société puisse collecter, traiter et utiliser des Données
                                    Personnelles le concernant conformément à la Politique de Confidentialité.</p>
                                <p>Le Visiteur et l’Utilisateur acceptent d'être potentiellement soumis auTtraitement de
                                    Données Personnelles. En utilisant le Site, le Visiteur ou l’Utilisateur consent à
                                    ce Traitement et garanti que toutes les Données Personnelles fournies sont
                                    exactes.</p>
                                <blockquote>La Propriété Intellectuelle</blockquote>
                                <p>La Société détient tous les droits de Propriété Intellectuelle sur le Site.</p>
                                <p>La Propriété intellectuelle se définit comme désigne (a) toutes les inventions
                                    qu’elles soient brevetables ou non, et qu’elles aient ou non une application
                                    industrielle identifiée et toute les améliorations qui s’y rapporteraient, ainsi que
                                    tous les brevets et les demandes de brevets, ainsi que toutes autres demandes
                                    qu’elles soient initiales ou divisionnaires, (b) toutes les marques et autres signes
                                    distinctifs, déposés ou non, enregistrés ou non et les logos et les slogans qui s’y
                                    rapportent, en ce compris les dénominations sociales, (c) tous textes et autres
                                    ouvrages ou travaux susceptibles d’être protégés par des droits d’auteur (d) toutes
                                    typographies ou semi conducteurs (e) tous secrets de fabrique, méthodes
                                    commerciales, procédés de laboratoire et autres savoir-faire qu’elle qu’en soit la
                                    nature ou
                                    l’objet (f) tous certificats d’obtention végétale (g) tous dessins ou modèles ou
                                    autres dessins industriels déposés ou non (h) tous droits relatifs à des logiciels,
                                    enregistrés ou non, qu’ils s’agissent de droit de propriété industrielle, de droit
                                    dérivé du droit d’auteur ou de titularité de savoir faire, (i) tous les droits sur
                                    les bases de données (j) tous droits sur des noms de domaine quel qu’en soit le
                                    suffixe, ainsi que sur les site internet qu’il s’agissent de leur structure, de leur
                                    apparence et de leur contenu, (k) plus généralement tous les droits sur des actifs
                                    incorporels susceptibles de correspondre à la protection d’une idée et (l) toutes
                                    les reproductions qu’elle qu’en soit la forme, corporelle ou incorporelle, des
                                    objets de droit intellectuel ci-dessus énumérés.</p>
                                <p>Les droits de Propriété Intellectuelle et tous les autres droits de propriété
                                    relatifs au contenu disponible sur le Site notamment la mise en page, le code
                                    source, les informations affichées et techniques associées au Site sont la propriété
                                    exclusive de la Société.</p>
                                <p>Tous les droits de Propriété Intellectuelle qui ne sont pas expréssement attribués
                                    demeurent ceux de la Société. Cela est le cas notamment pour les textes produits,
                                    technologie(s), contenu, code source, design et graphisme ainsi que tout autre
                                    élément qui apparait sur le Site.</p>
                                <p>L'accès au Site n'accorde pas de licence sur les droits de Propriété Intellectuelle
                                    de tiers.</p>
                                <p>Toute utilisation d’un élément de Propriété Intellectuelle doit recueillir
                                    l’autorisation préalable écrite de la Société.</p>
                                <blockquote>Accessibilité</blockquote>
                                <p>Il se peut que le Site ne soit pas toujours accessible selon la juridiction où se
                                    situe le Visiteur ou l’Utilisateur. La Société peut restreindre l’accès partiel ou
                                    total au Site dans certaines zones géographiques.
                                </p>
                                <p>Il se peut que le Site ne soit pas toujours accessible en raison de mises à jour, la
                                    Société fera ses meilleurs efforts pour en notifier le Visiteur ou
                                    l’Utilisateur.</p>
                                <p>La Société n’est pas responsable des dommages subis en raison de l’inaccessibilité du
                                    Site.</p>
                                <p>
                                    La Société n’est pas responsable des dommages subis en raison de contenu tiers
                                    accessibles via des liens depuis le Site.</p>
                                <p><span style={{fontStyle: 'bold'}}>L’Utilisateur reconnait qu’il utilise le Site à son seul risque.</span>
                                </p>

                                <blockquote>Force majeure</blockquote>
                                <p>La Société ne peut être tenue pour responsable des dommages, pertes, retards ou
                                    désagréments causés par des circonstances échappant à son contrôle raisonnable. Ces
                                    circonstances comprennent, sans s'y limiter, la guerre, la menace de guerre, les
                                    émeutes,
                                    les troubles civils ou les activités terroristes, les conflits du travail, les
                                    catastrophes naturelles ou nucléaires, les incendies, les fermetures d'aéroports,
                                    les mauvaises conditions météorologiques, l'interruption ou la défaillance d'un
                                    service public ou les actes d'un gouvernement local ou national.</p>
                                <blockquote>Liens externes</blockquote>
                                <p>Le Site peut contenir des liens externes qui redirige les Visiteurs ou les
                                    Utilisateurs auprès de sites tiers. La Société n’a aucune responsabilité vis à vis
                                    de ces liens externes et ne garantie en aucun cas la pertinence, la véracité ou la
                                    sécurité du contenu de ces liens ou du site tiers.</p>
                                <blockquote>Clause d’indivisibilité</blockquote>
                                <p>Les présentes Condition Générale d’Utilisation du Site, la Politique de
                                    Confidentialité et la Politique de Cookies constituent la totalité de la relation
                                    contractuelle avec la Société.</p>
                                <p>Si une disposition des présentes conditions est jugée invalide par un tribunal ou une
                                    juridiction compétente, cette disposition sera limitée au minimum nécessaire et les
                                    autres dispositions resteront pleinement en vigueur.</p>
                                <blockquote>Non-renonciation</blockquote>
                                <p>Le fait que la Société n'applique pas une disposition des Conditions Générales
                                    d’Utilisation du Site ne constitue pas une renonciation à son droit de le faire à
                                    l'avenir en ce qui concerne cette disposition, toute autre disposition ou les
                                    Conditions Générales d’Utilisation du Site dans leur ensemble.</p>
                                <blockquote>Cession</blockquote>
                                <p>Aucun droit, licence ou obligation en vertu des Conditions Générales d’Utilisation du
                                    Site ne peut être cédés ou transférés sans l’accord préalable écrit de la
                                    Société.</p>
                                <blockquote>Loi applicable et juridiction</blockquote>
                                <p>Les Conditions Générales d’Utilisation du Site et l’utilisation du Site, ainsi que toutes les questions qui en découlent ou qui y sont liées (y compris les litiges ou réclamations non contractuels et leurs interprétations), sont régies par le droit français, à l'exclusion des règles relatives aux conflits de lois.</p>
                                <p>Toute réclamation ou tout litige concernant les Conditions Générales d’Utilisation du Site ou en relation avec celles-ci (y compris les litiges ou réclamations non contractuels et leur interprétation) relève de la compétence exclusive des tribunaux français.</p>
                                <p>La Société et le Visiteur et/ou Utilisateur s’efforce de régler prioritairement les conflits par la voie amiable de règlement des litiges.</p>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <FooterV1/>
        </>
    );
}

export default Legal;