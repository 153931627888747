import React from "react"
import StripeCheckoutButton from '../library/StripeCheckoutButton';

const Btnprimary = ({text, action}) => {
    return (
        <>
            <div className="mb-3 mb-0 text-center">
                <button className="theme-btn" type="button" onClick={action}>
                    {text}
                </button>
            </div>
        </>
    );
}

export default Btnprimary