import functions from './functions'
export async function restconnect(params, method){
    const f = functions;
    let obj = params;
    let tasks = ['passreset', 'login', 'checktoken', 'setpass', 'insertuser', 'sendcontactemail', 'confirmemail'];
    if(!tasks.includes(params['task'])) {
        if (f.getSessionValid()) {
            obj.session = f.getSessionId();
        } else {
            console.log("etter")
            localStorage.clear();
            document.location.href = '/'
        }
    }

    let devurl = '/php/?reload=' + new Date().getTime();
    let options = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Host': window.location.pathname
        },
        mode:'cors',
        body: JSON.stringify(obj)
    };
    let response = await fetch(devurl, options);
    if (response) {
        let data = await response.json();
        if(data.hasOwnProperty('logout')){
            /* Log user out */
            localStorage.clear();
            //document.location.href = '/'
        } else {
            return data;
        }
    }
}