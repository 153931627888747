import React, { useState } from "react";
import { restconnect } from "../modules/restconnect";
import { Link } from "react-router-dom";

const Contact = () => {
  const [message, setMessage] = useState("");

  let form = {
    firstname: "",
    lastname: "",
    email: "",
    comment: "",
  };

  function textOnChange(e) {
    form[e.target.id] = e.target.value;
  }

  function validateForm() {
    for (const key in form) if (form[key].trim() === "") return false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(form.email);
  }

  function onClickSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setMessage("Envoyé - Veuillez vérifier votre boîte de réception de spam");
      restconnect({ task: "sendcontactemail", form: form }, "POST").then(
          (r) => {
            setMessage(r["mail"]);
          }
      );
    } else {
      setMessage("Veuillez remplir tous les champs svp.");
    }
  }

  return (
      <section className="contact_page">
        <section className="gallery-section-two overlay pt-40 rpt-95 pb-40 rpb-65 px-60 rpx-0 tw-mb-10">
          <div className="container-fluid">
            <div className="section-title text-center">
              <h2>Contact</h2>
            </div>
            <div className="row text-white"></div>
          </div>
        </section>
        <div className="container mt-30">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-form ml-40 rml-0 rmt-55">
                <h3 className="comment-title mb-35">
                  Contactez-nous pour échanger davantage
                </h3>
                <p>
                  Un expert en cybersécurité prendra contact avec vous dans les meilleurs délais.
                </p>
                <div className="comment-form mt-35">
                  <div className="row clearfix justify-content-center">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="firstname">
                          <i className="far fa-user"></i>
                        </label>
                        <input
                            type="text"
                            id="firstname"
                            name="first-name"
                            className="form-control"
                            placeholder="Prénom"
                            autoComplete="off"
                            required
                            onChange={textOnChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="lastname">
                          <i className="far fa-user"></i>
                        </label>
                        <input
                            type="text"
                            id="lastname"
                            name="last-name"
                            className="form-control"
                            placeholder="Nom de famille"
                            autoComplete="off"
                            required
                            onChange={textOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix justify-content-center">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">
                          <i className="far fa-envelope"></i>
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            autoComplete="off"
                            required
                            onChange={textOnChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row clearfix justify-content-start">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="comment">
                          <i className="fas fa-pencil-alt"></i>
                        </label>
                        <textarea
                            name="comments"
                            id="comment"
                            className="form-control"
                            rows="4"
                            placeholder="Message"
                            autoComplete="off"
                            required
                            onChange={textOnChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group mb-0">
                        {message !== "" && (
                            <>
                              {message} <br />
                            </>
                        )}
                        <button className="theme-btn" onClick={onClickSubmit}>
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-form ml-40 rml-0 rmt-55">
                <h3 className="comment-title mb-35">Prendre rendez-vous</h3>
                <p>Réserver un rendez-vous pour un entretien gratuite.</p>
                <Link
                    to="https://meetings-eu1.hubspot.com/tom-bonnot"
                    target="_blank"
                >
                  <button className="theme-btn">Prendre rendez-vous</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mapouter tw-pt-10">
              <iframe
                  className="gmap_iframe col-12"
                  title="Contact"
                  style={{ height: 300 + "px" }}
                  src="https://maps.google.com/maps?width=501&amp;height=400&amp;hl=en&amp;q=50+Rue+Gustave+Eiffel+01630+Saint-Genis-Pouilly%2C+France&amp;t=&amp;z=10&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Contact;
