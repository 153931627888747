import React from 'react';

const PdfViewer = ({ pdfUrl }) => {
    const openPdf = () => {
        window.open(pdfUrl, '_blank');
    };

    return (
        <button onClick={openPdf}>Open PDF</button>
    );
};

export default PdfViewer;