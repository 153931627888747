import React from "react";
import { ChevronRight, Info } from "lucide-react";
import { Link } from "react-router-dom";
import Doughnut from "../../library/donut";
import { Tooltip } from "react-tooltip";
import Bar from "../../library/BarChart";

export default function BarGraphicsCategories({ scores, title, tooltip }) {
  return (
    <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-flex tw-flex-1 tw-flex-col tw-gap-8">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between tw-relative">
        <div className="chart-container tw-wrapper-donut tw-relative tw-flex tw-justify-center tw-flex-1 tw-items-center tw-mx-auto tw-w-full tw-max-w-96">
          <Bar scores={scores} />
        </div>
        <div className="tw-flex tw-justify-center tw-gap-5">
          <span className="tw-text-md tw-font-semibold">
            {title}
          </span>
          <Tooltip id="my-tooltip" />
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={tooltip}
            data-tooltip-place="top-start"
          >
            <Info />
          </a>
        </div>
      </div>
    </div>
  );
}
