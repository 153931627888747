import React, { Component } from 'react';
import { restconnect } from '../modules/restconnect.js';
import functions from '../modules/functions.js';
import Btnprimary from "../library/btnprimary";
import Messagediv from "../library/messagediv";
import Paragraph from "../library/paragraph";
import setpassform from "../modules/setpassform";
import { formaudit } from "../modules/formcheck";
import { Link } from "react-router-dom";

let resetcount = 0;

class Authenticate extends Component {

    constructor(props) {
        super(props);
        this.f = functions;
        this.passform = setpassform;
        this.audit = formaudit;
        this.state = {
            username: "", password: "", errormessage: "", formpurpose: "login", headline: "",
            subheadline: "", passwordreset: (this.f.getPartOfUrl(1) === 'setpassword'),
            showpassform: false, token: '', password1: '', password2: '', match: false, userid: null,
            passmessage: '',
        }
        this.subheadings = {
            login: "Veuillez saisir votre e-mail et votre mot de passe pour accéder au site",
            reset: "Inscrivez votre courriel et suivez les instructions.",
            setpassword: "Choisissez un nouveau mot de passe pour votre compte"
        }
    }

    componentDidMount() {
        if (this.state.passwordreset && resetcount === 0) {
            resetcount++;
            this.setState({ headline: "Choisir le mot de passe" });
            this.setState({ subheadline: this.subheadings.setpassword });
            this.setState({ token: this.f.getLastPartOfUrl() })
            let params = { 'task': 'checktoken', 'token': this.f.getLastPartOfUrl() };

            restconnect(params, 'POST').then(r => {
                if (r.hasOwnProperty('regcomplete')) {
                    this.setState({ userid: r['regcomplete'] })
                    this.setState({ showpassform: true });
                } else {
                    this.setState({ errormessage: 'Le token d\'autorisation n\'est pas valide' });
                    setTimeout(() => {
                        this.setState({ passwordreset: false });
                        this.setState({ errormessage: '' });
                        this.setState({ headline: "Se connecter" });
                        this.setState({ subheadline: this.subheadings.login });
                    }, 4000);
                }
            })
        } else {
            this.setState({ headline: "Se connecter" });
            this.setState({ subheadline: this.subheadings.login });
        }
    }

    changePurpose = () => {
        this.setState({ formpurpose: (this.state.formpurpose === 'login') ? 'changepass' : 'login' });
        this.setState({ errormessage: '' });
        this.setState({ headline: (this.state.formpurpose === 'login') ? 'Demandez un nouveau mot de passe' : 'Accedez' });
        this.setState({
            subheadline: (this.state.formpurpose === 'login') ? this.subheadings.reset : this.subheadings.login
        });
    }

    resetpass = () => {
        let params = { 'task': 'passreset', 'email': this.state.username, 'link': this.f.getLocationUrl() + '/setpassword/' };
        restconnect(params, 'POST').then(r => {
            if (r.hasOwnProperty('reset')) {
                this.setState({ errormessage: 'Veuillez vérifier votre e-mail et suivre les instructions pour réinitialiser votre mot de passe.' });
            } else {
                this.setState({ errormessage: 'L\'email indiqué n\'existe pas' });
            }
        });
    }

    setpass = () => {
        let params = { 'task': 'setpass', 'pass': this.passform.form.password1.value, 'token': this.state.userid }
        this.setState({ errormessage: 'Communication avec le serveur.' })
        restconnect(params, 'PATCH').then(r => {
            if (r.hasOwnProperty('error')) {
                this.setState({ errormessage: 'Ups..... C\'est une erreur de notre part. Erreur de serveur.' })
            } else {
                this.setState({ errormessage: 'Mot de pass sauvegardé' })
                setTimeout(() => {
                    this.setState({ showpassform: false });
                    this.setState({ formpurpose: 'login' });
                    this.setState({ passwordreset: false });
                    this.setState({ errormessage: '' });
                    this.setState({ headline: "Se connecter" });
                    this.setState({ subheadline: this.subheadings.login });
                }, 2500);
            }
        })
    }

    loginwithserver = (e) => {
        e.preventDefault();
       if (this.state.formpurpose === 'login') {
            this.setState({ errormessage: '' })
            let param = { 'task': 'login', 'email': this.state.username, 'pass': this.state.password };
            restconnect(param, 'PATCH').then(r => {
                if (r.hasOwnProperty('user')) {
                    if (r['user'].hasOwnProperty('error')) {
                        this.setState({ errormessage: r['user'].error });
                    } else {
                        this.setState({ errormessage: 'Bienvenue ' + r['user']['ufirstname'] + ' ' + r['user']['ulastname'] });
                        this.f.storeObject('user', r['user']);
                        this.f.storeObject('products', r['products']);
                        this.f.createSessionObject(r['user']['session']);
                        if ( r['user']['uemail'] === 'admin@sybe-solutions.com') {
                            setTimeout(() => {
                                this.f.setActivityListener();
                                window.location.href = this.f.getLocationUrl() + '/dashboard';
                            }, 1000);
                        } else {
                            setTimeout(() => {
                                this.f.setActivityListener();
                                window.location.href = this.f.getLocationUrl() + '/summary';
                            }, 1000);
                        }
                    }
                } else {
                    this.setState({ errormessage: 'Mot de passe ou utilisateur incorrect.' });
                }
            });
        } else if (this.state.formpurpose === 'changepass') {
            this.setState({ errormessage: 'Communication avec le serveur.' });
            this.resetpass();
        }
    }

    comparepasswords = function () {
        let audit = this.audit(this.passform);
        if (audit.success) {
            if (this.passform.form.password2.value !== '') {
                if (this.passform.form.password1.value === this.passform.form.password2.value) {
                    this.setState({ match: true })
                    this.setState({ passmessage: 'Match' });
                } else {
                    this.setState({ match: false })
                    this.setState({ passmessage: 'Pas de match' });
                }
            }
        }
    }

    usernameonchange = event => { this.setState({ username: event.target.value }); }
    passwordonchange = event => { this.setState({ password: event.target.value }); }
    handelSetNewPassword = (e) => {
        this.passform.form[e.target.id].value = e.target.value;
        this.comparepasswords();
    }

    render() {
        return (
            <>
                <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative" >
                    <div className="container" >
                        <div className="row justify-content-center" >
                            <div className="col-xxl-4 col-lg-5">
                                <div className="card">

                                    <div className="card-body p-4">
                                        <div className="text-center w-75 m-auto">
                                            <h3 className="text-dark-50 text-center pb-0 fw-bold">Se connecter</h3>
                                            <Paragraph value={this.state.subheadline} customstyle={'mb-4'} />
                                        </div>
                                        {!this.state.passwordreset &&
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Adresse e-mail</label>
                                                    <input className="form-control" id="email"
                                                           autoComplete="email username"
                                                           onChange={this.usernameonchange}
                                                           type="email"
                                                           placeholder="Saisissez votre e-mail" />
                                                </div>

                                                <div className="mb-3">
                                                    {(this.state.formpurpose === "login") && <>
                                                        <label htmlFor="password" className="form-label">Mot de passe</label>
                                                        <div className="input-group input-group-merge">
                                                            <input type="password" id="password"
                                                                   autoComplete="current-password"
                                                                   onChange={this.passwordonchange}
                                                                   className="form-control simpleinput"
                                                                   hidden={(this.state.formpurpose !== "login")}
                                                                   placeholder="Saisissez votre mot de passe" />
                                                        </div>
                                                    </>}
                                                </div>
                                                <Messagediv message={this.state.errormessage} />
                                            </form>
                                        }

                                        {this.state.showpassform &&
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="password1" className="form-label">Saisir le nouveau mot de passe</label>
                                                    <div className="input-group input-group-merge">
                                                        <input type="password" id="password1"
                                                               autoComplete="current-password"
                                                               onChange={this.handelSetNewPassword}
                                                               className="form-control simpleinput"
                                                               placeholder="Saisissez votre mot de passe" />
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="password2" className="form-label">Repetez le mot de passe</label>
                                                    <div className="text-muted float-end">
                                                        <small>{this.state.passmessage}</small>
                                                    </div>
                                                    <div className="input-group input-group-merge">
                                                        <input type="password" id="password2"
                                                               autoComplete="current-password"
                                                               onChange={this.handelSetNewPassword}
                                                               className="form-control simpleinput"
                                                               placeholder="Repetez le mot de passe" />
                                                    </div>
                                                </div>
                                                {!this.state.match &&
                                                    <div className="text-muted float-start">
                                                        <small>
                                                            <ul>
                                                                <li>Le mot de passe doit contenir des lettres majuscules et minuscules</li>
                                                                <li>Le mot de passe doit contenir une chiffre</li>
                                                                <li>Le mot de passe doit contenir un caractère spécial</li>
                                                                <li>Le mot de passe doit contenir au moins 10 caractères</li>
                                                            </ul>
                                                        </small>
                                                    </div>}
                                                {this.state.match && <Btnprimary text={"Sauvegarder le mot de passe"} action={this.setpass} />}
                                            </form>

                                        }
                                        {this.state.passwordreset && <Messagediv message={this.state.errormessage} />}
                                        {!this.state.passwordreset && <>

                                            <div className="mb-3 mb-0 text-center">
                                                <button className="theme-btn w-10"
                                                        onClick={this.loginwithserver}>{(this.state.formpurpose === 'login') ? "Se connecter" : "Réinitialiser le mot de passe"}</button>
                                            </div>
                                            <div className="mb-3 mb-0 text-center">
                                                <Link className="theme-btn w-10" type="button" to={"/inscribe"}>Inscrivez
                                                    vous</Link>
                                            </div>
                                            <div className="mb-3 mb-0 text-center">
                                                <button className="btn btn-link text-center" onClick={this.changePurpose}>
                                                    <small>{(this.state.formpurpose === 'login') ? "Réinitialiser le mot de passe" : "Retour au login"}</small>
                                                </button>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Authenticate;
