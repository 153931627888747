import React, { useState } from "react";
import { CirclePlus, CircleMinus } from "lucide-react";

const Accordion = ({ accordionData, bgColor }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordionIndex(activeAccordionIndex === index ? null : index);
  };

  return (
    <section className="accordion-wrapper container">
      <div className="accordion tw-flex tw-flex-col tw-gap-8">
        {accordionData.map((data, index) => (
          <div
          key={index}
          className={`accordion-item tw-cursor-pointer tw-flex tw-flex-col tw-gap-8 tw-border-gray-300 tw-border-solid tw-border tw-p-6 tw-rounded-xl tw-group hover:tw-shadow tw-transition-all tw-200 hover:tw-transition-all hover:tw-200
          ${bgColor ? `tw-bg-${bgColor}` : ""}`
        }
          onClick={() => toggleAccordion(index)}
        >
            <div
              className="accordion-title tw-flex"
              
            >
              <h2 className="tw-m-0 !tw-text-xl tw-flex tw-w-full">{data.title}
                {activeAccordionIndex === index ? (
                  <CircleMinus className="tw-ml-auto tw-text-main-blue " />
                ) : (
                  <CirclePlus className="tw-ml-auto tw-text-main-blue group-hover:tw-transition-all group-hover:tw-rotate-90" />
                )}
              </h2>
            </div>
            <div
              className={`${
                activeAccordionIndex === index ? "tw-flex" : "accordion-content tw-hidden"
              }`}
            >
              <p className="tw-flex tw-justify-start tw-text-left tw-text-gray-400">{data.content}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Accordion;
