import React from "react";
import PdfViewer from "../library/PdfViewer";
const Cookies = () => {
    return (
        <>
            <PdfViewer pdfUrl="../assets/pdc.pdf" />
        </>
    );
}

export default Cookies;