import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./components/home";
import Contact from "./components/contact";
import Solutions from "./components/solutions";
import Portfoliodetails from "./components/portfoliodetails";
import Authenticate from "./components/authenticate";
import Survey from "./components/survey";
import About from "./components/about";
import Propos from "./components/propos";
import Legal from "./components/legal";
import Cookies from "./components/cookies";
import Blogdatails from "./components/blogdetails";
import Cybercompliance from "./components/cybercompliance";
import Teammember from "./components/teammember";
import Inscribe from "./components/inscribe";
import Topmenu from "./components/topmenu";
import Summary from "./components/summary";
import Footer from "./library/footer";
import Thankyouforregister from "./components/thankyouforregister";
import Confirm from "./components/confirm";
import Transactions from "./components/transactions";
import Dashboard from "./components/dashboard";
import Blog from "./components/blog";
import SurveyPDF from "./components/surveypdf"; // Import the new SurveyPDF component
import 'react-tooltip/dist/react-tooltip.css';
// import CookieConsent from 'react-cookie-consent';

function App() {
    return (
        <BrowserRouter>
            <div className="page-wrapper">
                <div className="jem_header_home" id="youtube-video">
                    <Topmenu/>
                </div>
                <Routes>
                    <Route path='/inscribecomplete' element={<Thankyouforregister/>}/>
                    <Route path='/confirm/:id' element={<Confirm/>}/>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/summary' element={<Summary/>}/>
                    <Route path='/dashboard' element={<Dashboard/>}/>
                    <Route path='/transactions' element={<Transactions/>}/>
                    <Route path='/authenticate' element={<Authenticate/>}/>
                    <Route path='/setpassword/:token' element={<Authenticate/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='/about' element={<About/>}/>
                    <Route path='/propos' element={<Propos/>}/>
                    <Route path='/cookies' element={<Cookies/>}/>
                    <Route path='/legal' element={<Legal/>}/>
                    <Route path='/teammember/:id' element={<Teammember/>}/>
                    <Route path='/cybercompliance' element={<Cybercompliance/>}/>
                    <Route path='/solutions' element={<Solutions/>}/>
                    <Route path='/portfoliodetails/:id' element={<Portfoliodetails/>}/>
                    <Route path='/blogdetails/:id' element={<Blogdatails/>}/>
                    <Route path='/inscribe' element={<Inscribe/>}/>
                    <Route path='/survey/:id' element={<Survey/>}/>
                    <Route path='/survey-pdf/:id' element={<SurveyPDF/>}/> {/* Add the new route */}
                    <Route path='/blog' element={<Blog />}/>
                </Routes>
            </div>
            <Footer className="mt-30"/>
        </BrowserRouter>
    );
}

export default App;
